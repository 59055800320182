import React from 'react';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Grid, Typography, FormControl, InputAdornment, Button, Tooltip, TextField, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IngredientsGroupLayout, IngredientsLabel } from '@novozymes-digital/laundry-lab/static/Constants';
import { Collection, Prices } from '@novozymes-digital/laundry-lab/store/types';
import {
  getIngredientsByRegionAndGroup,
  getRegions,
  getUserLocale,
  getUserUnits,
  getUserAllGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { colors } from '@novozymes-digital/components';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  groupSeparator: string;
  decimalSeparator: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, groupSeparator, decimalSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={3}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={groupSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString
    />
  );
}

interface Props {
  collection: Collection;
  updateValues: (values: Partial<Collection>) => void;
}

const StyledGrid = styled(Grid)(() => ({
  marginBottom: '16px',
}));

const StyledTooltip = styled(Tooltip)(() => ({
  marginLeft: '2px',
}));

const StyledTextField = styled(TextField)(() => ({
  '& > div:first-child': {
    borderRadius: '8px',
  },
}));

const IngredientCostTable: React.FunctionComponent<Props> = ({ collection, updateValues }) => {
  const weightUnits = useSelector(getUserUnits).weight;
  const userLocale = useSelector(getUserLocale);
  const ingredientsLayout = useSelector(getIngredientsByRegionAndGroup)[collection.region];
  const regions = useSelector(getRegions);
  const userAllGrants = useSelector(getUserAllGrants);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

  const setCost = (ingredientName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateValues({
      prices: {
        ...collection.prices,
        [ingredientName]: value,
      },
    });
  };

  const setZeroForEmpty = (ingredientName: string, event: any) => {
    const value = event.target.value;
    if (!value) {
      updateValues({ [ingredientName]: 0 });
    }
  };

  const handleSetDefaultCost = () => {
    updateValues({ prices: { ...regions[collection.region].prices } });
  };

  const stainGroups = Object.values(ingredientsLayout || {});

  const { groupSeparator, decimalSeparator } = userLocale;

  return (
    <Grid direction="column" spacing={3}>
      <Grid item container xs={12}></Grid>
      <Grid item container justifyContent="flex-end">
        <Button variant="outlined" size="small" onClick={handleSetDefaultCost}>
          Reset to default prices
        </Button>
      </Grid>
      <Grid item container alignContent="flex-start" spacing={4} wrap={'nowrap'}>
        {stainGroups.map(({ group_label, ingredients }: IngredientsGroupLayout, index: number) => (
          <Grid item sm={4} key={group_label} style={{ backgroundColor: index % 2 ? colors.bg3 : 'inherit' }}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="subtitle1">{group_label}</Typography>
              </Grid>
              {ingredients.map((ingredient: IngredientsLabel, index: number) => {
                const [ingredientName] = Object.keys(ingredient);
                const ingredientLabel = ingredient[ingredientName];

                if (ingredientName === 'medleyBrilliant400l' && !grantGlobalCosmed) {
                  return null; // Don't render
                }

                return (
                  <Grid item key={index}>
                    <FormControl fullWidth>
                      <StyledGrid item>
                        <Typography component="label" htmlFor={ingredientName}>
                          {ingredient.is_blend && !['la'].includes(collection.region)
                            ? `Blend: ${ingredientLabel}`
                            : ingredientLabel}
                        </Typography>
                        <StyledTooltip arrow placement="right" title={ingredient.tooltip}>
                          <InfoOutlinedIcon fontSize="small" />
                        </StyledTooltip>
                      </StyledGrid>
                      <StyledTextField
                        data-cy={'ingredients-input-' + ingredientName}
                        variant="outlined"
                        id={ingredientName}
                        name={ingredientName}
                        value={collection.prices[ingredientName as keyof Prices]}
                        onChange={setCost(ingredientName)}
                        onBlur={(event: any) => setZeroForEmpty(ingredientName, event)}
                        InputProps={{
                          inputComponent: NumberFormatCustom as any,
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ marginRight: '10px' }}
                            >{`${collection.currency}/${weightUnits}`}</InputAdornment>
                          ),
                        }}
                        inputProps={{
                          groupSeparator,
                          decimalSeparator,
                        }}
                        error={collection.prices[ingredientName as keyof Prices] < 0}
                        helperText={
                          collection.prices[ingredientName as keyof Prices] < 0 ? 'Negatives not allowed' : ' '
                        }
                      />
                    </FormControl>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default IngredientCostTable;
