import React from 'react';
import { Tabs, Tab, styled } from '@mui/material';
import { colors } from '@novozymes-digital/components';

interface Props {
  selected: number;
  labels: string[];
  onChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
}

interface StyledTabsProps {
  children: any;
  value: number;
  onChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)({
  '&.MuiTabs-root': {
    backgroundColor: colors.bg3,
    marginBottom: '20px',
    borderRadius: '16px',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

interface StyledTabProps {
  key: string;
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  '&.MuiTab-root': {
    textTransform: 'none',
    color: '#2e2e2e',
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: '10px',
    opacity: 0.7,
    '@media (max-width: 1419px)': {
      fontSize: '10px',
    },
    '@media (min-width:1420px)': {
      fontSize: '12px',
    },
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '20px',
    '&:focus': {
      opacity: '1',
    },
    '&[aria-selected="true"]': {
      opacity: '1',
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textDecorationColor: '#bfe6c4',
    },
  },
}));

const SectionTabs: React.FunctionComponent<Props> = ({ selected, labels, onChange }: Props) => {
  return (
    <StyledTabs value={selected} onChange={onChange}>
      {labels.map((label) => {
        return <StyledTab key={label} label={label} />;
      })}
    </StyledTabs>
  );
};

export default SectionTabs;
