import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, InputAdornment, Button, styled } from '@mui/material';
import { colors } from '@novozymes-digital/components';
import {
  getCurrentCollectionCurrency,
  getCurrentCollectionPrices,
  getIngredientList,
  getUserLocale,
  getUserUnits,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
import Slider from '@material-ui/core/Slider';

const StyledLabel = styled('label')({
  fontSize: '1rem',
  fontWeight: 'bold',
});

const StyledGrid = styled(Grid)({
  fontSize: '1rem',
  fontWeight: 'bold',
  minWidth: '204px',
  marginTop: '5px',
});

const StyledComment = styled('span')({
  fontSize: '12px',
  color: '#b5b5b5',
});

const StyledTextFieldOne = styled(TextField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiInput-input': {
    background: '#C5D1D3',
    color: colors.black67,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '45px',
    borderRadius: '4px',
  },
  '& .MuiInput-root:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInput-root:after': {
    borderBottom: 'none',
  },
});

const StyledTextFieldTwo = styled(TextField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiInput-input': {
    background: '#F8F7BF',
    color: colors.black67,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '45px',
    borderRadius: '4px',
  },
  '& .MuiInput-root:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInput-root:after': {
    borderBottom: 'none',
  },
});

const StyledButton = styled(Button)({
  fontSize: '12px',
  padding: '0px',
  minWidth: '35px',
  textDecoration: 'underline',
});

const StyledSlider = styled(Slider)`
  && {
    .MuiSlider-rail {
      background-color: #e5df00;
    }
    .MuiSlider-mark {
      height: 10;
      top: calc(50% - 5px);
      background-color: ${(props) => props.theme.palette.grey[600]};
    }
    .MuiSlider-markActive {
      background-color: ${(props) => props.theme.palette.grey[600]};
    }
    .MuiSlider-markLabel {
      font-size: 12px;
    }
    .MuiSlider-track {
      background-color: #164951;
    }
    .MuiSlider-thumb {
      background-color: #b3c800;
    }
  }
`;

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  groupSeparator: string;
  decimalSeparator: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, groupSeparator, decimalSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={groupSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString
    />
  );
}

interface Props {
  value?: number;
  ingredientsTotal: number;
  currentValues: any;
  fillersName: string[];
  setCurrentValues: any;
  onChangeOne?: (value: number) => void;
  onChangeTwo?: (value: number) => void;
  disabled?: boolean;
  interval?: number;
  displayInterval?: boolean;
  label?: string;
  legend?: string;
  step?: number;
}

const FillerDouble: React.FunctionComponent<Props> = ({
  ingredientsTotal,
  currentValues,
  value = currentValues.fillersName[0],
  fillersName,
  setCurrentValues,
  onChangeOne,
  onChangeTwo,
  label,
  legend,
  step = 5,
  ...props
}: Props) => {
  const currency = useSelector(getCurrentCollectionCurrency);
  const userUnits = useSelector(getUserUnits);
  const ingredientCosts: any = useSelector(getCurrentCollectionPrices);
  const ingredientList = useSelector(getIngredientList);
  const userLocale = useSelector(getUserLocale);
  const { groupSeparator, decimalSeparator } = userLocale;

  const max = ingredientsTotal;
  const min = 0;

  const [sliderValue, setSliderValue] = useState(value);
  const [fillerOneValue, setFillerOneValue] = useState(value);
  const [fillerTwoValue, setFillerTwoValue] = useState(value);
  const [oldIngredientsTotal, setOldIngredientsTotal] = useState(ingredientsTotal);
  const [overLimit, setOverLimit] = useState(false);

  const handleValueUpdating = useCallback(
    (value: string | number) => {
      let newValue = Number(value);
      if (newValue > max) {
        newValue = max;
      } else if (newValue < min) {
        newValue = min;
      }

      if (onChangeOne) {
        onChangeOne(newValue);
      }
      setSliderValue(newValue);
      setFillerOneValue(newValue);
      setFillerTwoValue(ingredientsTotal - newValue);
    },
    [max, min]
  );

  const handleValueUpdatingTwo = useCallback(
    (value: string | number) => {
      let newValue = Number(value);
      if (newValue > max) {
        newValue = max;
      } else if (newValue < min) {
        newValue = min;
      }
      if (onChangeTwo) {
        onChangeTwo(newValue);
      }
      setSliderValue(fillerOneValue);
      setFillerTwoValue(newValue);
    },
    [max, min]
  );

  const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    handleValueUpdating(Number(event.target.value));
  };

  const handleChangeTwo = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    handleValueUpdatingTwo(Number(event.target.value));
  };

  const handleTempChangeOne = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    if (Number(event.target.value) >= ingredientsTotal) {
      setFillerOneValue(ingredientsTotal);
      setSliderValue(ingredientsTotal);
      setFillerTwoValue(0);
    } else {
      setFillerOneValue(Number(event.target.value));
      setSliderValue(Number(event.target.value));
    }
  };

  const handleTempChangeTwo = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    if (Number(event.target.value) >= ingredientsTotal) {
      setFillerTwoValue(ingredientsTotal);
      setFillerOneValue(0);
      setSliderValue(0);
    } else {
      setFillerTwoValue(Number(event.target.value));
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    if (props.disabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    setSliderValue(newValue);
  };

  const handleSliderCommitChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    if (props.disabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    handleValueUpdating(newValue);
  };

  useEffect(() => {
    handleValueUpdating(value);
  }, [value]);

  // Max button one
  const maxFillerOne = () => {
    if (ingredientsTotal > 0) {
      setCurrentValues((newValues: any) => ({ ...newValues, [fillersName[0]]: ingredientsTotal }));
      setCurrentValues((newValues: any) => ({ ...newValues, [fillersName[1]]: 0 }));
      setFillerOneValue(ingredientsTotal);
      setFillerTwoValue(0);
      setSliderValue(fillerOneValue);
    }
  };

  // Max button two
  const maxFillerTwo = () => {
    if (ingredientsTotal > 0) {
      setCurrentValues((newValues: any) => ({ ...newValues, [fillersName[0]]: 0 }));
      setCurrentValues((newValues: any) => ({ ...newValues, [fillersName[1]]: ingredientsTotal }));
      setFillerTwoValue(ingredientsTotal);
      setFillerOneValue(fillerOneValue);
      setSliderValue(0);
    }
  };

  // Update fillers when ingredients change
  useEffect(() => {
    const old_f1_perc = trunc((currentValues[fillersName[0]] * 100) / oldIngredientsTotal, 2);
    const new_f1 =
      oldIngredientsTotal > 0
        ? trunc((100 * (ingredientsTotal * old_f1_perc)) / 100 / 100, 2)
        : currentValues[fillersName[0]];
    const new_f2 =
      oldIngredientsTotal > 0 ? trunc((100 * (ingredientsTotal - new_f1)) / 100, 2) : currentValues[fillersName[1]];

    if (ingredientsTotal <= 0) {
      setFillerOneValue(0);
      setFillerTwoValue(0);
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: 0,
      }));
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[1]]: 0,
      }));
      setOverLimit(true);
    } else if (ingredientsTotal > 0) {
      // filler 1
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: new_f1,
      }));
      // filler 2
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[1]]: new_f2,
      }));

      setFillerOneValue(new_f1);
      setFillerTwoValue(new_f2);
      setOldIngredientsTotal(ingredientsTotal);
      setOverLimit(false);
    }

    // when the ingredientsTotal go from >= 100% to < 100%
    if (ingredientsTotal > 0 && overLimit === true) {
      setOverLimit(false);
      // filler 1
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: ingredientsTotal / 2,
      }));
      // filler 2
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[1]]: ingredientsTotal / 2,
      }));
      setFillerOneValue(ingredientsTotal / 2);
      setFillerTwoValue(ingredientsTotal / 2);
    }
  }, [ingredientsTotal]);

  useEffect(() => {
    if (ingredientsTotal === 100 && currentValues[fillersName[0]] === 0) {
      setFillerOneValue(50);
      setFillerTwoValue(50);
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: 50,
      }));
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[1]]: 50,
      }));
    }
    if (ingredientsTotal === 100 && currentValues[fillersName[1]] === 100) {
      setFillerOneValue(0);
      setFillerTwoValue(100);
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: 0,
      }));
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[1]]: 100,
      }));
    }
  }, []);

  return (
    <Box p={0}>
      <Grid container item alignItems="center">
        <StyledLabel>{label}</StyledLabel>
      </Grid>
      {ingredientsTotal < 0 ? (
        <Typography variant="body2">The filler ingredients makes up a total of 0% of the detergent.</Typography>
      ) : (
        <Typography variant="body2">
          The filler ingredients makes up a total of {trunc(ingredientsTotal, 2)}% of the detergent.
        </Typography>
      )}
      <StyledGrid container justifyContent="space-around">
        <StyledTextFieldOne
          value={ingredientsTotal <= 0 ? 0 : trunc(fillerOneValue, 2)}
          id="filler-one"
          name="fillerOne"
          variant="standard"
          disabled={props.disabled}
          onBlur={handleChange}
          onChange={handleTempChangeOne}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: { min: min, max: 1000 },
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <span style={{ fontWeight: 'bold' }}>{legend}</span>
                </InputAdornment>
                <StyledButton variant="text" onClick={maxFillerOne}>
                  MAX
                </StyledButton>
              </>
            ),
          }}
          inputProps={{
            groupSeparator,
            decimalSeparator,
          }}
        />
        <StyledTextFieldTwo
          value={ingredientsTotal <= 0 ? 0 : trunc(fillerTwoValue, 2)}
          id="filler-two"
          name="fillerTwo"
          variant="standard"
          disabled={props.disabled}
          onBlur={handleChangeTwo}
          onChange={handleTempChangeTwo}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: { min: min, max: 1000 },
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <span style={{ fontWeight: 'bold' }}>{legend}</span>
                </InputAdornment>
                <StyledButton variant="text" onClick={maxFillerTwo}>
                  MAX
                </StyledButton>
              </>
            ),
          }}
          inputProps={{
            groupSeparator,
            decimalSeparator,
          }}
        />
      </StyledGrid>
      <StyledSlider
        value={sliderValue}
        marks={undefined}
        step={step}
        min={min}
        max={ingredientsTotal}
        valueLabelDisplay="off"
        onChange={onChangeOne ? handleSliderChange : undefined}
        onChangeCommitted={handleSliderCommitChange}
        {...props}
      />
      <Grid container justifyContent="space-around">
        {fillersName.map((filler) => (
          <Typography key={filler} variant="body2">
            {ingredientList[filler]}
          </Typography>
        ))}
      </Grid>
      <Grid container justifyContent="space-around">
        <StyledComment>{`${ingredientCosts[fillersName[0]]} ${currency}/${userUnits.weight}`}</StyledComment>
        <StyledComment>{`${ingredientCosts[fillersName[1]]} ${currency}/${userUnits.weight}`}</StyledComment>
      </Grid>
    </Box>
  );
};

export default FillerDouble;
