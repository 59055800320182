import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  Typography,
  IconButton,
  MenuItem,
  ListItemIcon,
  Chip,
  styled,
} from '@mui/material';
import { formatUnixTime } from '@novozymes-digital/laundry-lab/utility/Date';
import MenuPopover from '@novozymes-digital/laundry-lab/components/UI/MenuPopover';
import CollectionFolder from '@novozymes-digital/laundry-lab/icons/CollectionFolder';
import CollectionPinEmpty from '@novozymes-digital/laundry-lab/icons/CollectionPinEmpty';
import CollectionPinUnselected from '@novozymes-digital/laundry-lab/icons/CollectionPinUnselected';
import CollectionPinSelected from '@novozymes-digital/laundry-lab/icons/CollectionPinSelected';
import EditIcon from '@novozymes-digital/laundry-lab/icons/EditIcon';
import CloneIcon from '@novozymes-digital/laundry-lab/icons/CloneIcon';
import DeleteIcon from '@novozymes-digital/laundry-lab/icons/DeleteIcon';

import EmptyMessage from './EmptyMessage';
import Layout from '@novozymes-digital/laundry-lab/components/InputSelection/Layout';
import { getCollections, getCollectionsStatus, getRegions } from '@novozymes-digital/laundry-lab/store/selectors';
import { push, replace } from 'redux-first-history';
import { Collection } from '@novozymes-digital/laundry-lab/store/types';
import { useParams } from 'react-router';
import {
  cloneCollection,
  deleteCollection,
  pinCollection,
  sendCollection,
} from '@novozymes-digital/laundry-lab/store/actions/actions';
import { colors, OutlinedInputWithLabel } from '@novozymes-digital/components';
import SendIcon from '@novozymes-digital/laundry-lab/icons/SendIcon';
import validator from 'validator';
import NewDialog from '@novozymes-digital/laundry-lab/components/UI/NewDialog';

const StyledToggleButton = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
  borderBottom: `2px solid #e0e0e0`,
  cursor: 'pointer',
  '&.active': {
    borderColor: '#bfe6c4',
  },
}));

const StyledTable = styled(Table)(() => ({
  marginTop: '32px',
}));

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
}));

const StyledTableCellCenter = styled(StyledTableCell)(() => ({
  textAlign: 'center',
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  fontWeight: 400,
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: colors.bg3,
  },
}));

const StyledTableNameRow = styled(Grid)(() => ({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledChip = styled(Chip)(() => ({
  color: '#92A2A6',
  border: 'border: 1px solid #92A2A6',
  padding: '0px 16px 0px 16px',
  margin: '0px 0px 0px 8px',
  fontSize: '12px',
}));

const CollectionList: React.FC = () => {
  const { selection } = useParams<{ selection: string }>();
  const showPinned = selection === 'pinned';
  const [collectionToDelete, setCollectionToDelete] = useState<Collection>();
  const [collectionToSend, setCollectionToSend] = useState<Collection>();
  const dispatch = useDispatch();
  const collectionsStatus = useSelector(getCollectionsStatus);
  const collections = useSelector(getCollections);
  const regions = useSelector(getRegions);
  const pinnedCollections = collections.filter((collection) => collection.pinned);
  const collectionsToDisplay = showPinned ? pinnedCollections : collections;
  const togglePinned = useCallback(() => {
    dispatch(replace(showPinned ? '/collections' : '/collections/pinned'));
  }, [dispatch, showPinned]);

  const handleCreateCollection = useCallback(() => {
    dispatch(push('/collection'));
  }, [dispatch]);

  const handleEditCollection = useCallback(
    (id: number) => () => {
      dispatch(push(`/collection/${id}`));
    },
    [dispatch]
  );

  const handleDeleteCollection = (collection: Collection | undefined) => {
    if (!collection || !collection.id) return;

    dispatch(deleteCollection({ collectionId: collection.id }));
    setCollectionToDelete(undefined);
  };

  const handleCloneCollection = useCallback(
    (id: number) => () => {
      dispatch(cloneCollection({ collectionId: id }));
    },
    [dispatch]
  );

  const handleSendCollection = (collection: Collection | undefined) => {
    if (!collection || !collection.id) return;

    dispatch(sendCollection({ collectionId: collection.id, newUser: email }));
    setCollectionToSend(undefined);
  };

  const handleTogglePinned = useCallback(
    (collectionId: number, isCurrentlyPinned: boolean) => {
      dispatch(pinCollection({ collectionId, isCurrentlyPinned }));
    },
    [dispatch]
  );

  const handleOpenCollection = useCallback(
    (id?: string) => {
      dispatch(push(`/compare/${id}`));
    },
    [dispatch]
  );

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(true);

  const handleEmailChange = (event: React.ChangeEvent<{ value: string }>) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    setEmailValid(validateEmail(email));
  }, [email]);

  const validateEmail = (email: string) => {
    return validator.isEmail(email) && email.length > 0;
  };

  return (
    <>
      <NewDialog
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onClose={() => setCollectionToDelete(undefined)}
        onConfirm={() => handleDeleteCollection(collectionToDelete)}
        isOpen={!!collectionToDelete}
      >
        <Typography gutterBottom>
          Are you sure you want to delete <strong>{collectionToDelete?.name}</strong> collection?
        </Typography>
        <Typography variant="body2">Once you have deleted a collection you cannot get it back again.</Typography>
      </NewDialog>

      <NewDialog
        cancelButtonText="Cancel"
        confirmButtonText="Send"
        confirmButtonEnabled={emailValid}
        onClose={() => setCollectionToSend(undefined)}
        onConfirm={() => handleSendCollection(collectionToSend)}
        isOpen={!!collectionToSend}
      >
        <Typography variant="subtitle1" gutterBottom>
          Send Collection
        </Typography>
        <Typography variant="body1" gutterBottom>
          {collectionToSend && regions[collectionToSend?.region].name} | <strong>{collectionToSend?.name}</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          Email
        </Typography>
        <OutlinedInputWithLabel
          data-cy="send-email-input"
          placeholder="Email to receive collection"
          handleChange={handleEmailChange}
          error={!emailValid}
          errorMessage="The email is invalid"
        />
      </NewDialog>

      <Layout>
        <Grid container justifyContent="flex-end" spacing={3}>
          <Grid item>
            <StyledToggleButton
              data-cy="pinned-collection-list-tab"
              id="pinned-collection-list-tab"
              className={clsx({ active: showPinned })}
              onClick={togglePinned}
            >
              Pinned
            </StyledToggleButton>
          </Grid>
          <Grid item>
            <StyledToggleButton
              data-cy="all-collections-tab"
              id="full-collection-list-tab"
              className={clsx({ active: !showPinned })}
              onClick={togglePinned}
            >
              All collections
            </StyledToggleButton>
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            {!showPinned && (
              <Button
                data-cy="btn-open-newcollection-dialog"
                id="btn-open-newcollection-dialog"
                size="small"
                variant="contained"
                color="primary"
                onClick={handleCreateCollection}
                disabled={showPinned}
              >
                Create new collection
              </Button>
            )}
          </Grid>
        </Grid>
        {collectionsStatus === 'failed' && <> Failed getting collections </>}
        {(collectionsStatus === 'succeeded' || !!collectionsToDisplay.length) &&
          Object.keys(regions).length > 0 &&
          (collectionsToDisplay.length ? (
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell width="50" align="center">
                    <Grid container justifyContent="center">
                      <CollectionPinUnselected />
                    </Grid>
                  </TableCell>
                  <StyledTableHeadCell width="300">Collection name</StyledTableHeadCell>
                  <StyledTableHeadCell width="80">Region</StyledTableHeadCell>
                  <StyledTableHeadCell width="80">Formulations</StyledTableHeadCell>
                  <StyledTableHeadCell width="150">Last updated</StyledTableHeadCell>
                  <StyledTableCellCenter width="50"></StyledTableCellCenter>
                </TableRow>
              </TableHead>
              <TableBody>
                {collectionsToDisplay.map((collection) => (
                  <StyledTableRow key={collection.id}>
                    <StyledTableCellCenter>
                      <IconButton
                        id={`${collection.pinned ? 'pinned' : 'unpinned'}-collection-${collection.region}`}
                        data-cy={`${collection.pinned ? 'pinned' : 'unpinned'}-collection-${collection.name
                          .toLowerCase()
                          .replaceAll(' ', '-')}`}
                        size="small"
                        onClick={() => {
                          handleTogglePinned(collection.id!, collection.pinned);
                        }}
                      >
                        {collection.pinned ? <CollectionPinSelected /> : <CollectionPinUnselected />}
                      </IconButton>
                    </StyledTableCellCenter>
                    <StyledTableCell id={`cell-collection-${collection.id}`}>
                      <Grid
                        data-cy="container-collection"
                        container
                        spacing={1}
                        id={`container-collection-${collection.id}`}
                      >
                        <StyledTableNameRow
                          data-cy={'collection-table-cell-' + collection.name}
                          onClick={() => handleOpenCollection(collection.id?.toString())}
                          item
                        >
                          {collection.name}
                        </StyledTableNameRow>
                        {
                          // display NewChip if the collection has been cloned less than 24h ago
                          collection.created &&
                            collection.name.endsWith('(Clone)') &&
                            new Date(new Date().getTime()) < new Date(collection.created * 1000 + 86400000) && (
                              <StyledChip label="new" size="small" variant="outlined" />
                            )
                        }
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell>
                      {regions[collection.region].name || collection.region} (
                      {regions[collection.region].detergent_format})
                    </StyledTableCell>
                    <StyledTableCell>{collection.nrOfFormulations || 0} formulations</StyledTableCell>
                    <StyledTableCell>
                      {collection.lastUpdated ? formatUnixTime(collection.lastUpdated) : ''}
                    </StyledTableCell>
                    <StyledTableCellCenter data-cy={'btn-collection-menu-' + collection.name}>
                      <MenuPopover>
                        <MenuItem
                          onClick={handleEditCollection(collection.id!)}
                          data-cy={'btn-collection-edit-' + collection.name}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <Typography>edit</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setCollectionToSend(collection);
                          }}
                          data-cy={'btn-collection-send-' + collection.name}
                        >
                          <ListItemIcon>
                            <SendIcon />
                          </ListItemIcon>
                          <Typography>send a copy</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloneCollection(collection.id!)}
                          data-cy={'btn-collection-clone-' + collection.name}
                        >
                          <ListItemIcon>
                            <CloneIcon />
                          </ListItemIcon>
                          <Typography>clone</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setCollectionToDelete(collection);
                          }}
                          data-cy={'btn-collection-delete-' + collection.name}
                        >
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <Typography>delete</Typography>
                        </MenuItem>
                      </MenuPopover>
                    </StyledTableCellCenter>
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledTable>
          ) : showPinned ? (
            <EmptyMessage
              icon={<CollectionPinEmpty />}
              header={'You have no pinned collections'}
              msg={'Pin collections in your list to make them appear here'}
            />
          ) : (
            <EmptyMessage
              icon={<CollectionFolder />}
              header={'You have not created any collections'}
              msg={'After you create collections they will appear here'}
            />
          ))}
      </Layout>
    </>
  );
};

export default CollectionList;
