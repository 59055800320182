import { IngredientsInputLayoutsType, Region } from '../static/Constants';
import {
  Collection,
  Currency,
  EcolabelResult,
  Formulation,
  FormulationColor,
  Prices,
  StainRemovalResult,
  StateInterface,
  Status,
  SustainabilityResult,
  RegionType,
  StainRegions,
  Opportunities,
} from './types';
import { SnackColor } from '@novozymes-digital/components';

type Selector<T> = (state: StateInterface) => T;

export const getIsAuthLoading: Selector<boolean> = (state) => state.authState.status === 'loading';

export const getUsername: Selector<string> = (state) => state.authState.username;

export const getUserGrants: Selector<string[]> = (state) => {
  return state.authState.userGrants
    .filter((x) => x.includes(state.authState.environment))
    .map((x) => x.replace(`_${state.authState.environment}`, ''));
};

export const getUserAllGrants: Selector<string[]> = (state) => {
  return state.authState.userGrants;
};

export const getMessage: Selector<string> = (state) => state.authState.message;

export const getCollections: Selector<Collection[]> = (state) => state.appState.collections;

export const getCollectionById =
  (id: number): Selector<Collection | undefined> =>
  (state) =>
    state.appState.collections.find((collection) => collection.id === id);

export const getCurrentCollectionId: Selector<number | undefined> = (state) => state.appState.activeCollectionId;

export const getCurrentCollection: Selector<Collection | undefined> = (state) => {
  const id = getCurrentCollectionId(state);
  if (!id) {
    return undefined;
  }
  return getCollectionById(id)(state);
};

export const getCurrentCollectionName: Selector<string> = (state) => {
  const currentCollection = getCurrentCollection(state);
  return currentCollection?.name || '';
};

export const getCurrentCollectionRegion: Selector<RegionType> = (state) => {
  const currentCollection = getCurrentCollection(state);
  return currentCollection?.region || 'eu';
};
export const getCurrentCollectionDose: Selector<number> = (state) => {
  const currentCollection = getCurrentCollection(state);
  return currentCollection?.dose || 0;
};

export const getCurrentCollectionTemp: Selector<number> = (state) => {
  const currentCollection = getCurrentCollection(state);
  return currentCollection?.temperature || 0;
};

export const getCurrentCollectionPrices: Selector<Prices | undefined> = (state) => {
  const currentCollection = getCurrentCollection(state);
  return currentCollection?.prices;
};

export const getCurrentCollectionCurrency: Selector<Currency | undefined> = (state) => {
  const currentCollection = getCurrentCollection(state);
  return currentCollection?.currency;
};

export const getCollectionsStatus: Selector<Status> = (state) => state.appState.collectionsStatus;

export const getUserUnits: Selector<{ weight: string }> = () => ({ weight: 'kg' });
export const getUserLocale: Selector<{
  locale: string;
  groupSeparator: string;
  decimalSeparator: string;
}> = () => ({
  locale: 'en-US',
  groupSeparator: ',',
  decimalSeparator: '.',
});

export const getFormulations: Selector<Formulation[]> = (state) => state.appState.formulations;

export const getSelectedFormulations: Selector<string[]> = (state) => state.appState.selectedFormulations;

export const getFormulationById: Selector<(id: string) => Formulation | undefined> = (state) => (id) =>
  state.appState.formulations.find((formulation) => formulation.id === id);

export const getFormulationsByIds: Selector<(ids: string[]) => Formulation[] | undefined> = (state) => (ids) =>
  state.appState.formulations.filter((formulation) => ids.includes(formulation.id));

export const getSelectedFormulationsData: Selector<Formulation[]> = (state) =>
  state.appState.formulations.filter((formulation) => state.appState.selectedFormulations.includes(formulation.id));

export const getSelectedBaselineId: Selector<string | undefined> = (state) => state.appState.selectedBaseline;

export const getSelectedBaselineData: Selector<Formulation | undefined> = (state) => {
  const selectedFormulations = getSelectedFormulations(state);
  if (selectedFormulations?.length < 2) {
    return undefined;
  }
  const id = getSelectedBaselineId(state);
  return id ? getFormulationById(state)(id) : undefined;
};

export const getMaxOfBaselineFormulations: Selector<number> = (state) => {
  const selectedBaseline = getSelectedBaselineData(state);
  const formulations = getFormulations(state);
  let max = 0;

  // no baseline the max is 0
  if (!selectedBaseline) return max;

  formulations.forEach((formulation: Formulation) => {
    if (formulation.name.startsWith(selectedBaseline.name)) {
      // parse the copy number from the formulation name: "Mid Tier HDL - Copy (###)""
      const index_copy_start = formulation.name.indexOf(' - Copy (');
      const index_copy_end = formulation.name.indexOf(')', index_copy_start);
      const copy = parseInt(formulation.name.substring(index_copy_start + ' - Copy ('.length, index_copy_end));

      // copy isn't a number: then max is still max
      // otherwise compare copy to max and return if higher than max
      max = isNaN(copy) ? max : Math.max(copy, max);

      // console.log('max: ' + max + ' | copy: ' + copy + ' | ' + formulation.name.substring(index_copy_start + 9, index_copy_end));
    }
  });

  return max;
};

export const getMaxFormulationsSortOrder: Selector<number> = (state) => {
  const formulations = getFormulations(state);
  let max = 0;

  formulations.forEach((formulation: Formulation) => {
    max = isNaN(formulation.sort_order) ? max : Math.max(formulation.sort_order, max);
  });

  return max;
};

export const getStainRemovalResultByFormulationId: Selector<(id: string) => StainRemovalResult | undefined> =
  (state) => (id) =>
    state.appState.calculationResults[id]?.stainRemovalResult;

export const getSustainabilityResultsById: Selector<(id: string) => SustainabilityResult | undefined> =
  (state) => (id) =>
    state.appState.calculationResults[id]?.sustainabilityResult;

export const getEcolabelResultsById: Selector<(id: string) => EcolabelResult | undefined> = (state) => (id) =>
  state.appState.calculationResults[id]?.ecolabelResult;

export const getIsCreateFormulationPanelOpen: Selector<boolean> = (state) => state.appState.newFormulationPanelOpen;

export const getIsEditFormulationPanelOpen: Selector<boolean> = (state) => state.appState.editFormulationPanelOpen;

export const getEditFormulationData: Selector<Formulation | undefined> = (state) =>
  state.appState.formulations.find((formulation) => formulation.id === state.appState.editFormulationId);

export const getEditFormulationIsSelected: Selector<boolean | undefined> = (state) =>
  state.appState.editFormulationSelected;

export const getNewFormulationData: Selector<Formulation | undefined> = (state) => state.appState.newFormulation;

export const getFormulationsColor: Selector<FormulationColor[]> = (state) => state.appState.selectedFormulationsColor;

export const getIsGeneratingExcelExportData: Selector<boolean> = (state) => state.appState.isGeneratingExcelExportData;

export const getStainGroups: Selector<Record<string, Record<string, string>>> = (state) => state.appState.stainGroups;

export const getStainCustomGroups: Selector<any> = (state) => state.appState.stainCustomGroups;

export const getIngredientsByRegionAndGroup: Selector<IngredientsInputLayoutsType> = (state) =>
  state.appState.ingredients;

export const getIngredientList: Selector<Record<string, string>> = (state) => {
  const ingredientList: Record<string, string> = {};
  for (const region of Object.keys(state.appState.ingredients)) {
    for (const stainGroup of state.appState.ingredients[region as RegionType]) {
      for (const ingredient of stainGroup.ingredients) {
        ingredientList[Object.keys(ingredient)[0]] = Object.values(ingredient)[0];
      }
    }
  }

  return ingredientList;
};

export const getIngredientMaxValue: Selector<Record<string, Record<string, number>>> = (state) => {
  const regionIngredients: Record<string, Record<string, number>> = {};
  for (const region of Object.keys(state.appState.ingredients)) {
    const ingredients: Record<string, number> = {};
    for (const stainGroup of state.appState.ingredients[region as RegionType]) {
      for (const ingredient of stainGroup.ingredients) {
        ingredients[Object.keys(ingredient)[0]] = ingredient.max_value;
      }
    }
    regionIngredients[region] = ingredients;
  }

  return regionIngredients;
};

export const getIsExportEnabled: Selector<boolean> = (state) => {
  return state.appState.editFormulationId && state.appState.editFormulationPanelOpen ? false : true;
};

export const getIsResponseCurveEnabled =
  (region: string): Selector<boolean> =>
  (state) => {
    if (state.appState.editFormulationId && state.appState.editFormulationPanelOpen) return false;

    for (const stainGroup of state.appState.ingredients[region as RegionType]) {
      for (const ingredient of stainGroup.ingredients) {
        if (ingredient.response_curve) {
          return true;
        }
      }
    }

    return false;
  };

export const getRevision: Selector<number> = (state) => state.appState.revision;

export const getNotificationMessage: Selector<string> = (state) => state.appState.notificationMessage;

export const getNotificationStatus: Selector<SnackColor> = (state) => state.appState.notificationStatus;

export const getRegions: Selector<Region> = (state) => state.appState.regions;

export const getOpportunities: Selector<Opportunities[]> = (state) => state.appState.opportunities;

export const getStainsRegions: Selector<StainRegions[]> = (state) => state.appState.stainsRegions;

export const getBlendLimits: Selector<Record<string, Record<string, { [key: number]: number }>>> = (state) => {
  const regionBlends: Record<string, Record<string, { [key: number]: number }>> = {};

  for (const region of Object.keys(state.appState.blends)) {
    const blends: Record<string, { [key: number]: number }> = {};
    for (const blend of state.appState.blends[region as RegionType]) {
      blends[blend.blend] = blend.limits;
    }

    regionBlends[region] = blends;
  }

  return regionBlends;
};

export const getBlendEnzymes: Selector<Record<string, Record<string, { [key: string]: any }>>> = (state) => {
  const blendEnzymes: Record<string, Record<string, { [key: string]: any }>> = {};

  for (const region of Object.keys(state.appState.blends)) {
    const blends: Record<string, { [key: string]: any }> = {};
    for (const blend of state.appState.blends[region as RegionType]) {
      blends[blend.blend] = blend.enzymes;
    }

    blendEnzymes[region] = blends;
  }

  return blendEnzymes;
};

export const getBlendDisclaimer: Selector<Record<string, Record<string, string>>> = (state) => {
  const blendEnzymes: Record<string, Record<string, string>> = {};

  for (const region of Object.keys(state.appState.blends)) {
    const blends: Record<string, string> = {};
    for (const blend of state.appState.blends[region as RegionType]) {
      blends[blend.blend] = blend.disclaimer;
    }

    blendEnzymes[region] = blends;
  }

  return blendEnzymes;
};

export const getEnzymesBlends: Selector<Record<string, Record<string, string[]>>> = (state) => {
  const enzymesBlends: Record<string, Record<string, string[]>> = {};

  for (const region of Object.keys(state.appState.blends)) {
    const enzymes: Record<string, string[]> = {};

    for (const blend of state.appState.blends[region as RegionType]) {
      for (const enzyme of blend.enzymes as any[]) {
        const enzymeName = enzyme.name;

        if (enzymeName in enzymes) {
          enzymes[enzymeName].push(blend.blend);
        } else {
          enzymes[enzymeName] = [blend.blend];
        }
      }
    }

    enzymesBlends[region] = enzymes;
  }

  return enzymesBlends;
};

export const getIsFormulationOpen: Selector<boolean> = (state) =>
  state.appState.newFormulationPanelOpen || state.appState.editFormulationPanelOpen;
