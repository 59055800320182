import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Typography, styled } from '@mui/material';
import { colors, DropDown } from '@novozymes-digital/components';

interface action {
  id: string;
  name: string;
  mark?: boolean;
  label?: string;
}

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    border-radius: 16px;
    ul {
      margin: 16px;
    }
    li {
      padding: 8px;
    }
  }
  .MuiMenuItem-gutters {
    /* margin: 16px; */
    &:hover {
      border-radius: 16px;
      background-color: #dcddcd;
    }
  }
`;

const BoxDot = styled(Box)(() => ({
  width: '0.6rem',
  height: '0.6rem',
  borderRadius: '50%',
  margin: '0.3rem',
  marginTop: '-0.5rem',
  background: '#bfe6c4',
  border: `1px solid ${colors.spaceGrey}`,
}));

interface Props {
  actions: any;
  onClickAction: (id: string) => void;
  toggleIcon: any;
  label: string;
}

export default function ActionsMenu({ actions, onClickAction, toggleIcon, label }: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (id: string) => {
    handleClose();
    onClickAction(id);
  };

  return (
    <>
      <IconButton
        id="actionmenu-handle"
        color="inherit"
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography variant="body2">{label}</Typography>
        {(toggleIcon === 'dots' || !toggleIcon) && <MoreVertIcon fontSize="small" />}
        {toggleIcon === 'bottomArrow' && <DropDown fontSize="small" />}
      </IconButton>
      <StyledMenu
        disableAutoFocusItem
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {actions.map((action: action, index: number) => {
          return (
            <MenuItem id={action.id} key={index} onClick={() => handleAction(action.id)}>
              {action.name} {action.mark && <BoxDot />}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}
