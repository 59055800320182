import { Collection, Currency, RegionType } from '@novozymes-digital/laundry-lab/store/types';
import { Region } from '@novozymes-digital/laundry-lab/static/Constants';

export const getCollectionDefaults = ({
  region,
  username,
  regions,
}: {
  region: RegionType;
  username: string;
  regions: Region;
}): Collection => ({
  pinned: false,
  name: '',
  username,
  nrOfFormulations: 0,
  region,
  currency: regions[region].uom_currency[0] as Currency,
  temperature: regions[region].uom_temperature_options[0],
  dose: regions[region].dose_default,
  prices: regions[region].prices,
  detergent_volume: undefined,
  opportunity_id: undefined,
});
