import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Slide,
  Typography,
  Grid,
  Paper,
  InputLabel,
  useTheme,
  AppBar,
  Tabs,
  Tab,
  Button,
  styled,
} from '@mui/material';
import { Close, colors, OutlinedInputWithLabel, SelectWithLabel } from '@novozymes-digital/components';
import {
  getCurrentCollectionDose,
  getCurrentCollectionRegion,
  getCurrentCollectionTemp,
  getFormulations,
  getSelectedBaselineData,
  getMaxOfBaselineFormulations,
  getRegions,
  getIngredientsByRegionAndGroup,
  getBlendEnzymes,
  getEnzymesBlends,
  getMaxFormulationsSortOrder,
  getUserAllGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import DoseSlider from '@novozymes-digital/laundry-lab/components/UI/DoseSlider';
import { useDispatch, useSelector } from 'react-redux';
import {
  Enzyme,
  Filler,
  Formulation,
  OtherIngredient,
  Surfactant,
  Builder,
  Chemical,
} from '@novozymes-digital/laundry-lab/store/types';
import { updateNewFormulation } from '@novozymes-digital/laundry-lab/store/actions/actions';
import IngredientsInput from './IngredientsInput';
import { formatUnixDateTime } from '@novozymes-digital/laundry-lab/utility/Date';
import { IngredientsLabel, IngredientsGroupLayout } from '@novozymes-digital/laundry-lab/static/Constants';
import FillerSingle from '@novozymes-digital/laundry-lab/components/UI/FillerSingle';
import FillerDouble from '@novozymes-digital/laundry-lab/components/UI/FillerDouble';
import { isEqual } from 'lodash';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
interface FormulationPanelProps {
  title: string;
  show: boolean;
  onClose: (formulation: Formulation) => void;
  onSave: (formulation: Formulation) => void;
  style: React.CSSProperties;
  formulationData?: Formulation;
  limits: any;
}

const StyledPanelRoot = styled(Box)(() => ({
  position: 'absolute',
  zIndex: 1000,
  height: '100%',
  width: '100%',
  backgroundColor: colors.bg3,
}));

const StyledPanelHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledPanelHeaderHeading = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '22px',
}));

const StyledPaperStyle = styled(Paper)(() => ({
  backgroundColor: colors.bg3,
  boxShadow: 'none',
}));

const StyledTab = styled(Tab)(() => ({
  textTransform: 'none',
  minWidth: 85,
  paddingLeft: 0,
  paddingRight: 0,
  letterSpacing: '-.04em',
  color: '#b5b5b5',
}));

const StyledButton = styled(Button)(() => ({
  margin: '10px',
}));

const StyledCancel = styled(Button)(() => ({
  margin: '10px',
  '&:hover': {
    margin: '10px',
    borderRadius: '58px',
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: '#bfe6c4',
  },
}));

const StyledSelectWithLabel = styled(SelectWithLabel)(() => ({
  '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#bfe6c4',
  },

  '.MuiSelect-select:focus': {
    border: 'transparent',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      data-cy="full-width-tab"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const FormulationPanel = ({
  title,
  show,
  onClose,
  onSave,
  style,
  formulationData,
  limits,
}: FormulationPanelProps): JSX.Element => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const formulations = useSelector(getFormulations);

  const [errorMessage, setErrorMessage] = useState('');
  const [bFormNameExists, setBFormNameExists] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const region = useSelector(getCurrentCollectionRegion);
  const collectionDose = useSelector(getCurrentCollectionDose);
  const collectionTemp = useSelector(getCurrentCollectionTemp);
  const selectedBaselineData = useSelector(getSelectedBaselineData);
  const maxOfBaseline = useSelector(getMaxOfBaselineFormulations);
  const regions = useSelector(getRegions);
  const water_volume = useSelector(getRegions)[region].water_volume;
  const temperatureOptions = useSelector(getRegions)[region].uom_temperature_options;
  const ingredientsLayout = useSelector(getIngredientsByRegionAndGroup)[region];
  const dose_scale = useSelector(getRegions)[region].dose_scale;
  const blendsEnzymes = useSelector(getBlendEnzymes)[region];
  const enzymesBlends = useSelector(getEnzymesBlends)[region];
  const maxSortOrder = useSelector(getMaxFormulationsSortOrder);
  const currentDate = new Date();
  const userAllGrants = useSelector(getUserAllGrants);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

  const getDefaultValues = useCallback(
    (
      currentDate: Date,
      formulationData?: Formulation,
      baselineData?: Formulation,
      maxOfBaseline?: number
    ): Formulation => {
      // edit formulation
      if (formulationData) {
        return formulationData;
      }

      if (baselineData) {
        // create a new formulation from a baseline formulation
        return {
          ...baselineData,
          id: 'new_formulation',
          name: baselineData.name + ' - Copy (' + ((maxOfBaseline ? maxOfBaseline : 0) + 1) + ')',
          type: 'userFormulation',
        };
      }

      // create a formulation using the region default values
      return {
        id: 'new_formulation',
        name: 'New formulation ' + formatUnixDateTime(currentDate),
        sort_order: maxSortOrder + 1,
        type: 'userFormulation',
        aeo: 0,
        aes: 0,
        amplifyprime100l: 0,
        citrate: ['eu', 'cn', 'na'].includes(region)
          ? limits[region]['citrate'](Number(collectionDose), water_volume)
          : 0,
        dose: collectionDose || 0,
        las: 0,
        lipexevity200l: 0,
        mannaway200l: 0,
        progressuno100l: 0,
        liquanase35l: 0,
        soap: ['eu', 'cn', 'na'].includes(region) ? limits[region]['soap'](collectionDose || 0, water_volume) : 0,
        temperature: collectionTemp || 0,
        lipex100t: 0,
        mannaway4t: 0,
        savinase12t: 0,
        stainzymeplus12t: 0,
        celluclean4500t: 0,
        sodiumcarbonate: 0,
        sodiumsulfate: ['la', 'sea'].includes(region) ? 100 : 0,
        xpect1000l: 0,
        silicate: 0,
        savinase16t: 0,
        xpect1000t: 0,
        sodiumchloride: ['afr', 'ind', 'me'].includes(region) ? 50 : 0,
        dolomite: ['ind'].includes(region) ? 50 : 0,
        water_hardness: ['afr', 'me', 'ind', 'sea'].includes(region) ? 150 : 0,
        medleyCore200l: 0,
        medleyBoost300l: 0,
        medleyFlex300l: 0,
        medleyBright200l: 0,
        medleyAdvance300l: 0,
        medleyEssential200l: 0,
        medleySelect300l: 0,
        medleyPure300l: 0,
        medleyBrilliant300l: 0,
        medleyOptiwash1750s: 0,
        medleyOptiwash3500s: 0,
        medleyOptiwash400splus: 0,
        medleyAdvance200t: 0,
        medleyBrilliant400l: 0,
      };
    },
    [collectionDose, collectionTemp, region, water_volume]
  );

  const [extraProperties, setExtraProperties] = useState<Record<string, unknown>>({
    citrate: false,
  });
  const [currentValues, setCurrentValues] = useState(
    getDefaultValues(currentDate, formulationData, selectedBaselineData, maxOfBaseline)
  );

  useEffect(() => {
    setCurrentValues(getDefaultValues(currentDate, formulationData, selectedBaselineData, maxOfBaseline));
  }, []);

  const getKeyValue = (key: string) => (obj: Record<string, (dosage: number, water_volume: number) => number>) =>
    obj[key];

  const handleUpdateValue = (key: keyof Formulation, value: number | string) => {
    if (currentValues[key] == value) return;
    const newValues = {
      ...currentValues,
      region: region,
      [key]: value,
    };

    if (key === 'dose') {
      const dose = Number(value);
      const soapMaxDosage = ['eu', 'cn', 'na'].includes(region)
        ? limits[region]['soap'](Number(value), water_volume)
        : 0;
      setCurrentValues((newValues) => ({ ...newValues, ['soap']: soapMaxDosage }));

      surfactants.map((surfactant) => {
        const maxValue: number = getKeyValue(surfactant)(limits[region])(dose, water_volume);
        if (currentValues[surfactant] > maxValue) {
          setCurrentValues((newValues) => ({ ...newValues, [surfactant]: maxValue }));
        }
      });

      enzymes.map((enzyme) => {
        const maxValue: number = getKeyValue(enzyme)(limits[region])(dose, water_volume);
        if (currentValues[enzyme] > maxValue) {
          setCurrentValues((newValues) => ({ ...newValues, [enzyme]: maxValue }));
        }
      });

      others.map((other) => {
        const maxValue: number = getKeyValue(other)(limits[region])(dose, water_volume);
        if (currentValues[other] > maxValue) {
          setCurrentValues((newValues) => ({ ...newValues, [other]: maxValue }));
        }
      });

      // NA region
      const isCitrateOn = extraProperties.citrate;
      if (isCitrateOn) {
        const citrateMaxDosage = limits[region]['citrate'](Number(value), water_volume);
        setCurrentValues((newValues) => ({ ...newValues, ['citrate']: citrateMaxDosage }));
      }
    }

    setCurrentValues((newValues) => ({ ...newValues, [key]: value }));

    // calculate and update filler (only for LA)
    if (region === 'la') {
      const fillerValue: number =
        Math.round(
          (100 -
            newValues.lipex100t -
            newValues.mannaway4t -
            newValues.savinase12t -
            newValues.stainzymeplus12t -
            newValues.celluclean4500t -
            newValues.las -
            newValues.sodiumcarbonate -
            newValues.xpect1000l -
            newValues.medleyOptiwash1750s -
            newValues.medleyOptiwash3500s -
            newValues.medleyOptiwash400splus -
            newValues.medleyAdvance200t) *
            100
        ) / 100;
      setCurrentValues((newValues) => ({ ...newValues, ['sodiumsulfate']: fillerValue }));
    }
    // calculate and update filler (only for SEA)
    if (region === 'sea') {
      if (ingredientsTotal != null && ingredientsTotal > 0) {
        const fillerValue: number =
          Math.round(
            (100 -
              newValues.las -
              newValues.sodiumcarbonate -
              newValues.silicate -
              newValues.savinase16t -
              newValues.stainzymeplus12t -
              newValues.lipex100t -
              newValues.celluclean4500t -
              newValues.mannaway4t -
              newValues.xpect1000t) *
              100
          ) / 100;
        setCurrentValues((newValues) => ({ ...newValues, ['sodiumsulfate']: fillerValue }));
      } else {
        setCurrentValues((newValues) => ({ ...newValues, ['sodiumsulfate']: 0 }));
      }
    }
  };

  const [ingredientsTotal, setIngredientsTotal] = useState<number | undefined>();

  const ingredientsCalculation: number =
    Math.round(
      (100 -
        currentValues?.las -
        currentValues?.aes -
        currentValues?.aeo -
        currentValues?.amplifyprime100l -
        currentValues?.citrate -
        currentValues?.lipexevity200l -
        currentValues?.mannaway200l -
        currentValues?.progressuno100l -
        currentValues?.liquanase35l -
        currentValues?.soap -
        currentValues?.lipex100t -
        currentValues?.mannaway4t -
        currentValues?.savinase12t -
        currentValues?.stainzymeplus12t -
        currentValues?.celluclean4500t -
        currentValues?.sodiumcarbonate -
        currentValues?.xpect1000l -
        currentValues?.silicate -
        currentValues?.savinase16t -
        currentValues?.xpect1000t -
        currentValues?.medleyCore200l -
        currentValues?.medleyBoost300l -
        currentValues?.medleyFlex300l -
        currentValues?.medleyBright200l -
        currentValues?.medleyAdvance300l -
        currentValues?.medleyEssential200l -
        currentValues?.medleySelect300l -
        currentValues?.medleyPure300l -
        currentValues?.medleyBrilliant300l -
        currentValues?.medleyOptiwash1750s -
        currentValues?.medleyOptiwash3500s -
        currentValues?.medleyOptiwash400splus -
        currentValues?.medleyAdvance200t -
        currentValues?.medleyBrilliant400l) *
        100
    ) / 100;

  useEffect(() => {
    setIngredientsTotal(ingredientsCalculation);
  }, [ingredientsCalculation]);

  const ueSurfactantsMax = 8.1402 * currentValues.dose ** -0.775 * 100;
  const naSurfactantMax = 100;
  const cnSurfactantMax = 40;
  const surfactantsMax =
    region === 'eu' ? Math.round(ueSurfactantsMax * 10) / 10 : region === 'na' ? naSurfactantMax : cnSurfactantMax;

  useEffect(() => {
    dispatch(updateNewFormulation({ formulationData: currentValues, region: region }));

    const surfactantsSum = currentValues.las + currentValues.aes + currentValues.aeo;

    if (surfactantsSum > surfactantsMax && !['la', 'afr', 'me', 'ind', 'sea'].includes(region)) {
      setErrorMessage(`The sum of LAS, AES and AEO is ${
        Math.round(surfactantsSum * 10) / 10
      }%. It cannot be more than ${Math.round(surfactantsMax * 10) / 10}%. Please reduce %
      weight of these surfactants.`);
    } else if ((ingredientsTotal ?? 0) < 0) {
      setErrorMessage(`The sum of ingredients is ${Math.abs(
        ingredientsTotal ?? 0
      )}% above the limit. It cannot be more than 100%. Please reduce %
      weight of these ingredients.`);
    } else {
      setErrorMessage('');
    }
  }, [ingredientsTotal, currentValues, dispatch, region]);

  useEffect(() => {
    if (show && !selectedBaselineData) {
      setExtraProperties({ ...extraProperties, citrate: false });
    }

    if (selectedBaselineData) {
      setExtraProperties({ ...extraProperties, citrate: selectedBaselineData.citrate > 0 });
    }
  }, [show, selectedBaselineData]);

  const handleTempChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleUpdateValue('temperature', Number(event.target.value));
  };

  const handleWaterHardnessChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleUpdateValue('water_hardness', Number(event.target.value));
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: string }>) => {
    handleUpdateValue('name', event.target.value);

    if (
      !!event?.target?.value &&
      !!formulations.find(
        (formulation) =>
          formulation.name.toLowerCase() === event.target.value.toLowerCase() && formulation.id != currentValues.id
      )
    ) {
      setBFormNameExists(true);
    } else {
      setBFormNameExists(false);
    }
  };

  const handleDoseChange = (value: number) => {
    handleUpdateValue('dose', value);
  };

  const surfactantsSettings = (ingredientsLayout: IngredientsGroupLayout[]): Surfactant[] => {
    const surfactants: Surfactant[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Surfactants') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          surfactants.push(ingredient.name);
        });
      }
    });

    return surfactants;
  };
  const surfactants: Surfactant[] = surfactantsSettings(ingredientsLayout);

  const enzymesSettings = (ingredientsLayout: IngredientsGroupLayout[]): Enzyme[] => {
    const enzymes: Enzyme[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Enzymes') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          enzymes.push(ingredient.name);
        });
      }
    });

    return enzymes;
  };
  const enzymes: Enzyme[] = enzymesSettings(ingredientsLayout);

  const otherSettings = (ingredientsLayout: IngredientsGroupLayout[]): OtherIngredient[] => {
    const others: OtherIngredient[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Others') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          others.push(ingredient.name);
        });
      }
    });

    return others;
  };
  const others: OtherIngredient[] = otherSettings(ingredientsLayout);

  const builderSettings = (ingredientsLayout: IngredientsGroupLayout[]): Builder[] => {
    const builders: Builder[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Builders') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          builders.push(ingredient.name);
        });
      }
    });

    return builders;
  };
  const builders: Builder[] = builderSettings(ingredientsLayout);

  const chemicalsSettings = (ingredientsLayout: IngredientsGroupLayout[]): Chemical[] => {
    const chemicals: Chemical[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Chemicals') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          chemicals.push(ingredient.name);
        });
      }
    });

    return chemicals;
  };
  const chemicals: Chemical[] = chemicalsSettings(ingredientsLayout);

  const fillersSettings = (ingredientsLayout: IngredientsGroupLayout[]): Filler[] => {
    const fillers: Filler[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Fillers') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          fillers.push(ingredient.name);
        });
      }
    });

    return fillers;
  };
  const fillers: Filler[] = fillersSettings(ingredientsLayout);

  const [selectedTab, setSelectedTab] = useState(surfactants.length > 0 ? 0 : 1);

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleCancel = () => {
    setErrorMessage('');
    onClose(currentValues);
  };

  const handleSave = () => {
    setButtonClicked(!buttonClicked);

    if (!errorMessage) {
      onSave(currentValues);
    }
  };

  const stainGroups = Object.values(ingredientsLayout || {});

  // Disable/Enable enzymes/blends sliders
  useEffect(() => {
    if (blendsEnzymes) {
      // by default initialize the blends and enzymes as enabled
      const newExtraProperties: Record<string, boolean> = {};
      for (const blend of Object.keys(blendsEnzymes)) {
        newExtraProperties[blend] = false;
      }
      for (const enzyme of Object.keys(enzymesBlends)) {
        newExtraProperties[enzyme] = false;
      }

      // Blends
      for (const [blend, enzymes] of Object.entries(blendsEnzymes)) {
        const blendValue = Object.getOwnPropertyDescriptor(currentValues, blend)?.value || 0;

        if (blendValue > 0) {
          // place the other blends to inactive
          for (const blendToDisable of Object.keys(blendsEnzymes)) {
            if (blend !== blendToDisable) {
              newExtraProperties[blendToDisable] = true;
            }
          }

          // Disable enzymes
          const enzymeNamesToDisable = enzymes.map((enzyme: { name: string }) => enzyme.name);
          for (const enzymeToDisable of enzymeNamesToDisable) {
            newExtraProperties[enzymeToDisable] = true;
          }

          // Since only one blend can have > 0 at a time, we only need to find the first and then exit the loop
          break;
        } else {
          // If blendValue is 0, enable the enzymes
          const enzymeNamesToEnable = enzymes.map((enzyme: { name: string }) => enzyme.name);
          for (const enzymeToEnable of enzymeNamesToEnable) {
            newExtraProperties[enzymeToEnable] = false;
          }
        }
      }

      // Enzymes
      for (const [enzyme, blends] of Object.entries(enzymesBlends)) {
        const enzymeValue = Object.getOwnPropertyDescriptor(currentValues, enzyme)?.value || 0;

        if (enzymeValue > 0) {
          // place the respective blends to inactive
          for (const blendToDisable of Object.values(blends)) {
            newExtraProperties[blendToDisable] = true;
          }
        }
      }
      setExtraProperties({
        ...extraProperties,
        ...newExtraProperties,
      });
    }
  }, [currentValues]);

  return (
    <Slide direction="right" in={show} mountOnEnter unmountOnExit>
      <StyledPanelRoot style={style}>
        <StyledPanelHeader style={{ padding: '10px', paddingTop: 30 }}>
          <StyledPanelHeaderHeading style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '22px' }}>
            {title}
          </StyledPanelHeaderHeading>
          <Typography style={{ padding: '0 10px' }}>Not saved</Typography>
          <IconButton aria-label="close" onClick={handleCancel}>
            <Close />
          </IconButton>
        </StyledPanelHeader>

        <StyledPaperStyle square>
          <Grid container direction="column" spacing={3} style={{ padding: '10px' }}>
            <Grid item>
              <Grid container alignContent="flex-end" justifyContent="space-between">
                <InputLabel id="formulation-Region-label">Region</InputLabel>
                <Typography variant="body1">
                  {regions[region].name} ({regions[region].detergent_format})
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <InputLabel id="formulation-temperature-label">Temperature</InputLabel>
              <StyledSelectWithLabel
                value={currentValues.temperature}
                options={temperatureOptions.map((x) => {
                  return { label: `${x} °C`, value: `${x}`, disabled: false, key: `temperature_${x}` };
                })}
                handleChange={handleTempChange}
                placeholder="test"
                key={'temperature'}
                disabled={temperatureOptions.length > 1 ? false : true}
              />
            </Grid>
            {['afr', 'me', 'ind', 'sea'].includes(region) && (
              <Grid item>
                <InputLabel id="formulation-water_hardness-label">Water hardness</InputLabel>
                <StyledSelectWithLabel
                  value={currentValues.water_hardness}
                  options={[
                    { label: '150 ppm', value: '150', disabled: false, key: 'water_hardness_150' },
                    { label: '350 ppm', value: '350', disabled: false, key: 'water_hardness_350' },
                    { label: '450 ppm', value: '450', disabled: false, key: 'water_hardness_450' },
                  ]}
                  handleChange={handleWaterHardnessChange}
                  placeholder="test"
                  key={'water_hardness'}
                />
              </Grid>
            )}
            <Grid item>
              <DoseSlider
                value={currentValues.dose}
                min={regions[region].dose_min}
                max={regions[region].dose_max}
                step={regions[region].dose_step}
                interval={regions[region].dose_interval}
                disabled={regions[region].dose_disabled}
                displayInterval={true}
                onChange={handleDoseChange}
                label={'Dosage:'}
                legend={dose_scale}
              />
            </Grid>
          </Grid>
        </StyledPaperStyle>

        <StyledPaperStyle square elevation={0}>
          <AppBar
            position="relative"
            color="default"
            style={{ marginBottom: '1rem', padding: '0px 20px', backgroundColor: colors.bg1 }}
            elevation={0}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="secondary"
              aria-label="ingredient tabs"
              variant="fullWidth"
              scrollButtons="auto"
              data-cy="formulation-ingredient-tabs"
            >
              {surfactants.length > 0 && <StyledTab value={0} label="Surfactants" {...a11yProps(0)} />}
              {chemicals.length > 0 && <StyledTab value={1} label="Chemicals" {...a11yProps(4)} />}
              {builders.length > 0 && <StyledTab value={4} label="Builders" {...a11yProps(3)} />}
              {enzymes.length > 0 && <StyledTab value={2} label="Enzymes" {...a11yProps(1)} />}
              {others.length > 0 && <StyledTab value={3} label="Others" {...a11yProps(2)} />}
            </Tabs>
          </AppBar>
          {surfactants.length > 0 && (
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>
              {surfactants.map((surfactant) => (
                <IngredientsInput
                  dose={currentValues.dose}
                  key={surfactant}
                  ingredient={surfactant}
                  value={currentValues[surfactant]}
                  onValueChange={handleUpdateValue}
                  extraProperties={extraProperties}
                  setExtraProperties={setExtraProperties}
                  limits={limits}
                  region={region}
                  decimals={1}
                />
              ))}
            </TabPanel>
          )}
          {chemicals.length > 0 && (
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
              {chemicals.map((chemical) => (
                <IngredientsInput
                  dose={currentValues.dose}
                  key={chemical}
                  ingredient={chemical}
                  value={currentValues[chemical]}
                  onValueChange={handleUpdateValue}
                  extraProperties={extraProperties}
                  setExtraProperties={setExtraProperties}
                  limits={limits}
                  region={region}
                  decimals={1}
                />
              ))}
            </TabPanel>
          )}
          {enzymes.length > 0 && (
            <TabPanel value={selectedTab} index={2} dir={theme.direction}>
              {enzymes.map((enzyme) => {
                if (enzyme === 'medleyBrilliant400l' && !grantGlobalCosmed) {
                  return null; // Don't render IngredientsInput
                }

                return (
                  <IngredientsInput
                    dose={currentValues.dose}
                    key={enzyme}
                    ingredient={enzyme}
                    value={currentValues[enzyme]}
                    onValueChange={handleUpdateValue}
                    extraProperties={extraProperties}
                    setExtraProperties={setExtraProperties}
                    limits={limits}
                    region={region}
                    decimals={2}
                    currentValues={currentValues}
                  />
                );
              })}
            </TabPanel>
          )}
          {others.length > 0 && (
            <TabPanel value={selectedTab} index={3} dir={theme.direction}>
              {others.map((otherIngredient) => (
                <IngredientsInput
                  dose={currentValues.dose}
                  key={otherIngredient}
                  ingredient={otherIngredient}
                  value={currentValues[otherIngredient]}
                  onValueChange={handleUpdateValue}
                  extraProperties={extraProperties}
                  setExtraProperties={setExtraProperties}
                  limits={limits}
                  region={region}
                  decimals={1}
                />
              ))}
            </TabPanel>
          )}
          {builders.length > 0 && (
            <TabPanel value={selectedTab} index={4} dir={theme.direction}>
              {builders.map((builder) => (
                <IngredientsInput
                  dose={currentValues.dose}
                  key={builder}
                  ingredient={builder}
                  value={currentValues[builder]}
                  onValueChange={handleUpdateValue}
                  extraProperties={extraProperties}
                  setExtraProperties={setExtraProperties}
                  limits={limits}
                  region={region}
                  decimals={1}
                />
              ))}
            </TabPanel>
          )}
          <Grid container direction="column" spacing={3} style={{ padding: '10px' }}>
            <Grid item>
              {stainGroups.map(({ group_label, ingredients }: IngredientsGroupLayout) => {
                {
                  if (group_label === 'Fillers' && fillers.length > 1 && ingredientsTotal != null) {
                    return (
                      <FillerDouble
                        value={trunc(currentValues[fillers[0]], 2)}
                        currentValues={currentValues}
                        ingredientsTotal={trunc(ingredientsTotal, 2)}
                        fillersName={fillers}
                        setCurrentValues={setCurrentValues}
                        step={ingredients[0].step}
                        displayInterval={false}
                        label={'Fillers:'}
                        legend={'%'}
                        onChangeOne={(v: any) => {
                          if (ingredientsTotal > 0) {
                            setCurrentValues((newValues) => ({
                              ...newValues,
                              [fillers[0]]: trunc(100 * (v + Number.EPSILON), 1) / 100,
                            }));
                            setCurrentValues((newValues) => ({
                              ...newValues,
                              [fillers[1]]: trunc(100 * (ingredientsTotal - v + Number.EPSILON), 1) / 100,
                            }));
                          } else {
                            setCurrentValues((newValues: any) => ({
                              ...newValues,
                              [fillers[0]]: 0,
                            }));
                            setCurrentValues((newValues: any) => ({
                              ...newValues,
                              [fillers[1]]: 0,
                            }));
                          }
                        }}
                        onChangeTwo={(v: any) => {
                          if (ingredientsTotal > 0) {
                            setCurrentValues((newValues) => ({
                              ...newValues,
                              [fillers[0]]: trunc(100 * (ingredientsTotal - v + Number.EPSILON), 1) / 100,
                            }));
                            setCurrentValues((newValues) => ({
                              ...newValues,
                              [fillers[1]]: trunc(100 * (v + Number.EPSILON), 1) / 100,
                            }));
                          } else {
                            setCurrentValues((newValues: any) => ({
                              ...newValues,
                              [fillers[0]]: 0,
                            }));
                            setCurrentValues((newValues: any) => ({
                              ...newValues,
                              [fillers[1]]: 0,
                            }));
                          }
                        }}
                      />
                    );
                  }
                }
                {
                  if (group_label === 'Fillers' && ['sea', 'la'].includes(region) && ingredientsTotal != null) {
                    return (
                      <FillerSingle
                        ingredient={ingredients[0].name}
                        ingredientsTotal={ingredientsTotal}
                        setCurrentValues={setCurrentValues}
                        fillersName={fillers}
                        value={trunc(currentValues.sodiumsulfate, 1)}
                        min={0}
                        max={100}
                        step={ingredients[0].step}
                        displayInterval={false}
                        label={'Filler:'}
                        labelX={ingredients[0].label}
                        legend={'%'}
                        disabled={true}
                      />
                    );
                  }
                }
              })}
            </Grid>
          </Grid>
        </StyledPaperStyle>

        <StyledPaperStyle square>
          <Grid container direction="column" style={{ padding: '10px' }}>
            <Grid item>
              <InputLabel id="formulation-name-label">Formulation name</InputLabel>
              <OutlinedInputWithLabel
                data-cy="formulation-name-input"
                value={currentValues.name}
                placeholder="New formulation"
                handleChange={handleNameChange}
              />
            </Grid>
          </Grid>

          <Grid container style={{ padding: '10px' }}>
            {bFormNameExists && (
              <Typography variant="body2" color="error">
                The formulation name already exists.
              </Typography>
            )}

            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
          </Grid>
        </StyledPaperStyle>

        <Box display="flex" justifyContent="flex-end" paddingTop="10px">
          <StyledCancel data-cy="btn-cancel-create-formulation" color="inherit" onClick={handleCancel}>
            Cancel
          </StyledCancel>

          <StyledButton
            data-cy="btn-create-formulation"
            id="btn-create-formulation"
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={bFormNameExists || !!errorMessage || !currentValues.name || buttonClicked}
          >
            <Typography>Save formulation</Typography>
          </StyledButton>
        </Box>
      </StyledPanelRoot>
    </Slide>
  );
};

const compareProps = (prevProps: FormulationPanelProps, nextProps: FormulationPanelProps): boolean => {
  const prev = {
    formulationData: prevProps.formulationData,
    show: prevProps.show,
    title: prevProps.title,
  };
  const next = {
    formulationData: nextProps.formulationData,
    show: nextProps.show,
    title: nextProps.title,
  };
  const equal = isEqual(prev, next);
  return equal;
};

export default React.memo(FormulationPanel, compareProps);
