import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from './Layout';
import Chart from './Chart';
import Footnote from '../UI/Footnote';
import {
  getCurrentCollectionRegion,
  getSelectedBaselineData,
  getStainRemovalResultByFormulationId,
  getFormulationsColor,
  getStainGroups,
  getStainsRegions,
  getStainCustomGroups,
  getCurrentCollectionId,
  getRegions,
  getBlendDisclaimer,
  getIngredientsByRegionAndGroup,
  getUserAllGrants,
  getSelectedBaselineId,
  getBlendLimits,
} from '@novozymes-digital/laundry-lab/store/selectors';
import {
  createCustomStainGroup,
  setStainGroup,
  deleteStainCustomGroup,
  notificationShow,
} from '@novozymes-digital/laundry-lab/store/actions/actions';
import { Enzyme, Formulation, StainGroupCustom } from '@novozymes-digital/laundry-lab/store/types';
import StainTabs from './StainTabs';
import { NzButton, NzIconProps } from '@novozymes-digital/dezymer-core';
import EditStainsGroup from './EditStainsGroup';
import { Typography } from '@mui/material';
import { IErrorBarXDataPoint } from 'chartjs-chart-error-bars';
import { IngredientsGroupLayout, IngredientsLabel } from '@novozymes-digital/laundry-lab/static/Constants';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
import NewDialog from '../UI/NewDialog';

const sortFormulations = (formulations: Formulation[], baseLineId: string) => {
  if (!baseLineId) return formulations;

  return [...formulations].sort((a, b) => {
    return a.id === baseLineId ? 1 : b.id === baseLineId ? -1 : 0;
  });
};

interface AISEFootnoteProps {
  selectedGroup: string;
}

const STAIN_REMOVAL_STANDARD_GROUP_VALUE = 'Standard Groups';
const STAIN_REMOVAL_CUSTOM_GROUP_VALUE = 'Custom Groups';
const AISEFootnoteUrl =
  'https://www.aise.eu/our-activities/standards-and-industry-guidelines/laundry-detergent-testing-guidelines.aspx';

const AISEFootnote: React.FunctionComponent<AISEFootnoteProps> = ({ selectedGroup }: AISEFootnoteProps) => {
  if (selectedGroup === 'AISE') {
    return (
      <Footnote style={{ paddingTop: 0 }}>
        - AISE stain set according to protocol v.7. dated October 2020. More information on the protocol can be found{' '}
        <a href={AISEFootnoteUrl} target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </Footnote>
    );
  }
  return null;
};

interface ChartData {
  labels: string[];
  subLabels: string[];
  subSubLabels: string[];
  datasets: {
    label?: string;
    data: IErrorBarXDataPoint[];
    stack: string;
    baseline?: boolean;
    color?: string;
  }[];
}

interface Props {
  formulations: Formulation[];
}

const StainRemoval = ({ formulations }: Props): JSX.Element => {
  const getStainRemovalResultForFormulation = useSelector(getStainRemovalResultByFormulationId);
  const comparedFormulations = formulations;
  const baselineFormulation = useSelector(getSelectedBaselineData);
  const region = useSelector(getCurrentCollectionRegion);
  const stainGroupNamesForRegion = useSelector(getStainGroups)[region] || {};
  const stainCustomGroupNames = useSelector(getStainCustomGroups) || [];
  const stainsRegions = useSelector(getStainsRegions).filter((x) => x.id === region)[0] || [];
  const currentCollectionId = useSelector(getCurrentCollectionId);
  const regions = useSelector(getRegions);
  const blendDisclaimer = useSelector(getBlendDisclaimer)[region];
  const ingredientsLayout = useSelector(getIngredientsByRegionAndGroup)[region];
  const userAllGrants = useSelector(getUserAllGrants);
  const baselineId = useSelector(getSelectedBaselineId) || '';

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));
  const blendLimits = useSelector(getBlendLimits);

  const sortedFormulations = useMemo(() => sortFormulations(formulations, baselineId), [formulations, baselineId]);

  const [selectedGroup, setSelectedGroup] = useState({
    label: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
    type: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
    key: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
    id: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
  });
  const [editingGroup, setEditingGroup] = useState<any | null>(null);
  const [isOpenDeleteConfimationDialog, setIsOpenDeleteConfimationDialog] = useState(false);
  const formulationsColor = useSelector(getFormulationsColor);

  const allGroups = comparedFormulations.reduce<string[]>((acc, formulation) => {
    const result = getStainRemovalResultForFormulation(formulation.id);
    if (result) {
      const resultGroups = Object.keys(result).sort();
      return acc.concat(resultGroups.filter((group) => acc.indexOf(group) === -1));
    }
    return acc;
  }, []);

  const dispatch = useDispatch();

  const createNewGroup = () => {
    setEditingGroup({
      label: 'Custom ',
      type: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
      key: 'custom_create',
      //saved: false,
      mode: 'create',
      stainsLeft: stainsRegions.stains.map((x) => ({ label: x.display_name_eng, key: x.stain_id })),
      stainsRight: [],
      sort_order:
        stainCustomGroupNames.length > 0 ? Math.max(...stainCustomGroupNames.map((o: any) => o.sort_order)) + 1 : 0,
      id: undefined,
      collection_id: undefined,
    });
  };

  const editGroup = () => {
    const editGroup = stainCustomGroupNames.filter((x: any) => x.id == selectedGroup.id)[0];
    setEditingGroup({
      label: editGroup.stain_group_label,
      type: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
      key: editGroup.stain_group_label,
      //saved: false,
      mode: 'edit',
      stainsLeft: stainsRegions.stains.map((x) => ({ label: x.display_name_eng, key: x.stain_id })),
      stainsRight: editGroup.stains.map((x: any) => ({ label: x.stain.display_name_eng, key: x.stain_id })),
      sort_order: editGroup.sort_order,
      id: editGroup.id,
      collection_id: currentCollectionId,
    });
  };

  const confirmEditStainGroup = () => {
    editingGroup.label = editingGroup.label.trim();
    if (
      stainCustomGroupNames.filter(
        (stain: any) =>
          stain.stain_group_label.toLowerCase() === editingGroup.label.toLowerCase() && stain.id !== editingGroup.id
      ).length > 0 ||
      Object.values(stainGroupNamesForRegion).filter(
        (stain) => stain.toLowerCase() === editingGroup.label.toLowerCase()
      ).length > 0 ||
      [STAIN_REMOVAL_STANDARD_GROUP_VALUE.toLowerCase(), STAIN_REMOVAL_CUSTOM_GROUP_VALUE.toLowerCase()].includes(
        editingGroup.label.toLowerCase()
      )
    ) {
      dispatch(notificationShow({ message: 'Duplicate name', status: 'error' }));
      return;
    }
    if (editingGroup.mode === 'create') {
      const stainGroupData = {
        collection_id: currentCollectionId,
        stain_group: editingGroup.label,
        stain_group_label: editingGroup.label,
        sort_order: editingGroup.sort_order,
        stains: editingGroup.stainsRight.map((x: any) => x.key),
      };
      dispatch(createCustomStainGroup({ stainGroupData }));
      setSelectedGroup({
        label: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
        type: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
        key: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
        id: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
      });
    } else {
      const stainGroupData = {
        stain_group: editingGroup.label,
        stain_group_label: editingGroup.label,
        sort_order: editingGroup.sort_order,
        stains: editingGroup.stainsRight.map((x: any) => x.key),
        id: editingGroup.id,
        collection_id: currentCollectionId,
      };
      dispatch(setStainGroup({ stainGroupData }));
      setSelectedGroup({ ...selectedGroup, key: editingGroup.label, label: editingGroup.label });
    }
    setEditingGroup(null);
  };

  // opens the dialog to confirm delete
  const deleteStainGroup = () => {
    setIsOpenDeleteConfimationDialog(true);
  };

  const handleDeleteFormulation = () => {
    dispatch(deleteStainCustomGroup({ id: editingGroup.id, collection_id: currentCollectionId }));
    setEditingGroup(null);
    setIsOpenDeleteConfimationDialog(false);
    setSelectedGroup({
      label: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
      type: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
      key: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
      id: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
    });
    setEditingGroup(null);
  };

  const cancelStainGroup = () => {
    setEditingGroup(null);
  };

  const filteredStandartGroups = Object.entries(stainGroupNamesForRegion)
    .filter(([key]) => allGroups.indexOf(key) > -1)
    .map(([key, value]) => ({
      label: value,
      key: key,
      id: key,
      type: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
    }));
  const filteredCustomGroups = stainCustomGroupNames
    .filter((x: StainGroupCustom) => allGroups.indexOf(x.stain_group_label) > -1)
    .map((x: StainGroupCustom) => ({
      label: x.stain_group_label,
      key: x.stain_group_label,
      id: x.id,
      type: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
    }));
  const filteredGroups = [...filteredStandartGroups, ...filteredCustomGroups];

  const labels: ChartData['labels'] = [];
  const subLabels: ChartData['subLabels'] = [];
  const subSubLabels: ChartData['subSubLabels'] = [];
  const datasets: ChartData['datasets'] = [];

  comparedFormulations
    .filter((formulation) => !(!!baselineFormulation && formulation.id === baselineFormulation?.id))
    .forEach((formulation) => {
      const stainRemovalResult = getStainRemovalResultForFormulation(formulation.id) || {};
      const currentData = stainRemovalResult[selectedGroup.key];

      if (!currentData) {
        return {};
      }

      labels.push(
        ...Object.values(currentData)
          .map((stainData: any) => stainData.name)
          .filter((label) => !labels.includes(label))
      );

      subLabels.push(
        ...Object.values(currentData)
          .map((stainData: any) => stainData.label)
          .filter((code) => !subLabels.includes(code))
      );

      subSubLabels.push(
        ...Object.values(currentData)
          .map((stainData: any) => stainData.textile)
          .filter((code) => !subSubLabels.includes(code))
      );

      if (baselineFormulation) {
        // Each dataset needs a datapoint representing the baseline in their stack
        const baselineData = getStainRemovalResultForFormulation(baselineFormulation.id);
        if (baselineData && selectedGroup.key in baselineData) {
          datasets.push({
            label: baselineFormulation.name,
            data: Object.values(baselineData[selectedGroup.key]).map((stainData: any) => stainData.predicted),
            stack: formulation.name,
            baseline: true,
            color: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color,
          });
        }
      }
      datasets.push({
        label: formulation.name,
        data: Object.values(currentData).map((stainData: any) => ({
          x: stainData.predicted,
          xMin: stainData.predicted - stainData.standardDeviation,
          xMax: stainData.predicted + stainData.standardDeviation,
        })),
        stack: formulation.name,
        color: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color,
      });
    });

  if (
    selectedGroup.key === STAIN_REMOVAL_STANDARD_GROUP_VALUE ||
    selectedGroup.key === STAIN_REMOVAL_CUSTOM_GROUP_VALUE
  ) {
    comparedFormulations
      .filter((formulation) => !(!!baselineFormulation && formulation.id === baselineFormulation?.id))
      .forEach((formulation) => {
        const stainRemovalResult = getStainRemovalResultForFormulation(formulation.id) || {};
        const baselineDataSummary: any = {};
        const datasetDataSummary: any = {};
        const groupSelected = filteredGroups.filter((x) => x.type === selectedGroup.key || x.key === selectedGroup.key);
        for (const group of groupSelected) {
          const groupKey = group.key;
          const currentData = stainRemovalResult[groupKey];
          if (!currentData) return;

          if (!labels.includes(stainGroupNamesForRegion[groupKey] || groupKey)) {
            labels.push(stainGroupNamesForRegion[groupKey] || groupKey);
            subLabels.push(`${Object.keys(currentData)?.length} stains`);
          }

          if (baselineFormulation) {
            // Each dataset needs a datapoint representing the baseline in their stack
            const baselineData = getStainRemovalResultForFormulation(baselineFormulation.id);
            if (baselineData && groupKey in baselineData) {
              Object.values(baselineData[groupKey]).forEach((stainData) => {
                if (!baselineDataSummary[groupKey]) baselineDataSummary[groupKey] = 0;
                baselineDataSummary[groupKey] += stainData.predicted;
              });
            }
          }

          Object.values(currentData).forEach((stainData: any) => {
            if (!datasetDataSummary[groupKey]) datasetDataSummary[groupKey] = { predicted: 0, standardDeviation: 0 };
            datasetDataSummary[groupKey].predicted += stainData.predicted;
            datasetDataSummary[groupKey].standardDeviation += stainData.standardDeviation;
          });
        }

        if (baselineFormulation) {
          // Each dataset needs a datapoint representing the baseline in their stack
          const baselineData = getStainRemovalResultForFormulation(baselineFormulation.id);
          if (baselineData) {
            datasets.push({
              label: `${baselineFormulation.name} (Baseline)`,
              data: groupSelected.map((x) => x.key).map((groupKey: string) => baselineDataSummary[groupKey]),
              stack: formulation.name,
              baseline: true,
              color: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color,
            });
          }
        }

        datasets.push({
          label: formulation.name,
          data: groupSelected
            .map((x) => x.key)
            .map((groupKey: string) => ({
              x: datasetDataSummary[groupKey].predicted,
              xMin: datasetDataSummary[groupKey].predicted - datasetDataSummary[groupKey].standardDeviation,
              xMax: datasetDataSummary[groupKey].predicted + datasetDataSummary[groupKey].standardDeviation,
            })),
          stack: formulation.name,
          color: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color,
        });
      });
  }

  const data = {
    labels: labels,
    subLabels: subLabels,
    subSubLabels: subSubLabels,
    datasets: datasets,
  };

  const groupStains = [
    {
      label: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
      type: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
      key: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
      id: STAIN_REMOVAL_STANDARD_GROUP_VALUE,
    },
    {
      label: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
      type: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
      key: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
      id: STAIN_REMOVAL_CUSTOM_GROUP_VALUE,
    },
    ...filteredGroups,
  ];
  if (editingGroup && editingGroup.mode === 'create') {
    groupStains.push(editingGroup);
  }

  const editMediumRight: NzIconProps = {
    iconName: 'edit-icon',
    position: 'right',
    label: 'Edit',
  };

  const title = (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div>Stain removal</div>
      <div
        style={{
          marginLeft: 'auto',
        }}
      >
        <NzButton
          variant="primary"
          disabled={
            selectedGroup.type === STAIN_REMOVAL_STANDARD_GROUP_VALUE ||
            selectedGroup.label === STAIN_REMOVAL_CUSTOM_GROUP_VALUE ||
            editingGroup
          }
          onClick={() => editGroup()}
          size="medium"
          key="edit"
          icon={editMediumRight}
          theme="ghost"
        >
          Edit
        </NzButton>
      </div>
    </div>
  );

  // Get all blends
  const enzymesSettings = (ingredientsLayout: IngredientsGroupLayout[]): Enzyme[] => {
    const blends: Enzyme[] = [];

    ingredientsLayout.forEach((ingredientsGroup: IngredientsGroupLayout) => {
      if (ingredientsGroup.group_label == 'Enzymes') {
        ingredientsGroup.ingredients.forEach((ingredient: IngredientsLabel) => {
          if (ingredient.is_blend) {
            blends.push(ingredient.name);
          }
        });
      }
    });

    return blends;
  };

  const blends: Enzyme[] = enzymesSettings(ingredientsLayout);

  // Get the active blends from all the selected formulations
  const blendsWithValuesAboveZero: string[] = comparedFormulations.reduce((acc: string[], obj: any) => {
    const medleyKeys: string[] = Object.keys(obj).filter((key: any) => Object.values(blends).includes(key));
    const blendWithValueAboveZero: string | undefined = medleyKeys.find((key: string) => obj[key] > 0);
    if (blendWithValueAboveZero) {
      acc.push(blendWithValueAboveZero);
    }
    return acc;
  }, []);

  const outputArray: string[] = [];

  if (blendsWithValuesAboveZero.length > 0) {
    for (const blendName of blendsWithValuesAboveZero) {
      const disclaimerString = blendDisclaimer[blendName];

      const arrayOfWords = disclaimerString?.split('  ');
      const uniqueStrings = arrayOfWords?.filter((str, index) => {
        return arrayOfWords.indexOf(str) === index;
      });

      outputArray?.push(uniqueStrings?.join(' ').replace(/[^a-zA-Z],/g, ' '));
    }
  }
  const arrayOfWords = outputArray
    .join(' ') // Join all strings with a space
    .split(' '); // Split the resulting string into an array of words

  const newArray = arrayOfWords.map((string) => string.replace(',', '')); // Remove comma
  const uniqueArray = Array.from(new Set(newArray)).join(' '); // Add spaces between words

  // Max limit without the scaleFactor in medleyBrilliant400l
  const maxWithoutScaleFactor = (dosage: number): number => {
    return grantGlobalCosmed
      ? trunc(Object.entries(blendLimits['eu']['medleyBrilliant400l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2)
      : 0;
  };

  return (
    <>
      <NewDialog
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        onClose={() => setIsOpenDeleteConfimationDialog(false)}
        onConfirm={() => handleDeleteFormulation()}
        isOpen={isOpenDeleteConfimationDialog}
      >
        <Typography>{`You are about to delete ${selectedGroup.label}`}</Typography>
      </NewDialog>
      <Layout data-cy="stainremoval-data-panel" title={title} id="stainremoval">
        <StainTabs
          groupStains={groupStains}
          setTab={setSelectedGroup}
          selected={selectedGroup}
          standartType={STAIN_REMOVAL_STANDARD_GROUP_VALUE}
          customType={STAIN_REMOVAL_CUSTOM_GROUP_VALUE}
          editingGroup={editingGroup}
          setEditingGroup={setEditingGroup}
          setCreatMode={createNewGroup}
        />
        {editingGroup && (
          <EditStainsGroup
            stainsLeft={editingGroup.stainsLeft.filter((leftStain: any) =>
              editingGroup.stainsRight.every((rightStain: any) => rightStain.key !== leftStain.key)
            )}
            stainsRight={editingGroup.stainsRight}
            groupName={editingGroup.label}
            updateGroupList={(left: any, right: any) =>
              setEditingGroup({ ...editingGroup, stainsLeft: left, stainsRight: right })
            }
            onConfirm={confirmEditStainGroup}
            onDelete={deleteStainGroup}
            onCancel={cancelStainGroup}
            editMode={editingGroup.mode}
          />
        )}
        {!editingGroup && <Chart data={data} subtitle={regions[region].uom_stain_removal[0]} />}

        {(selectedGroup.label === 'AISE' || uniqueArray) && <Footnote style={{ paddingTop: 0 }}>Notes: </Footnote>}
        <AISEFootnote selectedGroup={selectedGroup.key} />
        {outputArray.length > 0 && !outputArray.every((item) => item === undefined) && (
          <Footnote style={{ paddingTop: 0 }}>
            - The stain removal prediction does not cover the effect of the following enzymes:{' '}
            <b>{uniqueArray?.toLowerCase()}</b>
          </Footnote>
        )}
        {outputArray.length > 0 &&
          !outputArray.every((item) => item === undefined) &&
          grantGlobalCosmed &&
          sortedFormulations.some((formulation) => formulation.medleyBrilliant400l > 0) && (
            <Footnote style={{ paddingTop: 0 }}>
              - The displayed protease effect is based on both model prediction and subject matter expertise.
              {sortedFormulations.some(
                (formulation) => formulation.medleyBrilliant400l > maxWithoutScaleFactor(formulation.dose)
              ) ? (
                <Footnote style={{ paddingTop: 0 }}>
                  - The displayed amylase effect might not be reflecting full amylase activity due to model limitation.
                </Footnote>
              ) : null}
            </Footnote>
          )}
        {region === 'la' && (
          <Footnote style={{ paddingTop: 0 }}>
            Disclaimer:{' '}
            <i>
              Delta E is a measurement that gives the differences in the color scale between the non-washed stain and
              the washed stain. It uses the color space parameters, L*a*b*, defined by the Commission Internationale de
              l’Eclairage (CIE). High values of Delta E indicate a more pronounced color difference between stains,
              which means better stain removal.
            </i>
          </Footnote>
        )}
      </Layout>
    </>
  );
};

export default StainRemoval;
