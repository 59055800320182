import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Typography, Divider, InputLabel, Tooltip, styled } from '@mui/material';

import Layout from '@novozymes-digital/laundry-lab/components/InputSelection/Layout';
import IngredientCostTable from './IngredientCostTable';
import { useParams } from 'react-router';
import {
  getCollectionById,
  getUsername,
  getRegions,
  getOpportunities,
  getUserAllGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { Collection, RegionType } from '@novozymes-digital/laundry-lab/store/types';
import { getCollectionDefaults } from './collectionDefaults';
import { push } from 'redux-first-history';
import { updateCollection, createCollection } from '@novozymes-digital/laundry-lab/store/actions/actions';
import { SelectWithLabel, OutlinedInputWithLabel } from '@novozymes-digital/components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
}));

const IngredientsGrid = styled(Grid)({
  '& .MuiGrid-root>.MuiGrid-item': {
    paddingRight: 14,
  },
});

const StyledTooltip = styled(Tooltip)(() => ({
  marginLeft: '2px',
}));

const StyledOutlinedInputWithLabel = styled(OutlinedInputWithLabel)`
  &:focus-visible {
    outline-color: crimson;
  }

  &:focus {
    border-color: #bfe6c4;
  }
`;

const StyledCancel = styled(Button)(() => ({
  '&:hover': {
    borderRadius: '58px',
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: '#bfe6c4',
  },
}));

const StyledSelectWithLabel = styled(SelectWithLabel)`
  display: 'flex';

  .MuiMenuItem-root {
    font-family: 'RaleGroteskBase';
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #bfe6c4;
  }

  .MuiSelect-select:focus {
    border-color: transparent;
  }
`;

const CollectionForm: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const collection = useSelector(getCollectionById(Number(id)));
  const username = useSelector(getUsername);
  const regions = useSelector(getRegions);
  const userAllGrants = useSelector(getUserAllGrants);
  const userOpportunities = useSelector(getOpportunities);
  // Options for the region selection
  const regionsOptions: {
    label: string;
    value: string;
    disabled: boolean;
  }[] = [];
  Object.values(regions).map((region) => {
    regionsOptions.push({
      label: region.active
        ? `${region.name} (${region.detergent_format})`
        : `${region.name} (${region.detergent_format} - under development, currently disabled)`,
      value: region.id,
      disabled: !region.active,
    });
  });
  // Options for opportunities
  const opportunitiesOptions: {
    label: string;
    value: string;
  }[] = [];
  Object.values(userOpportunities).map((opportunity) => {
    opportunitiesOptions.push({
      label: opportunity.name,
      value: opportunity.id,
    });
  });
  // Options for non opportunities
  opportunitiesOptions.push(
    { label: 'Opportunity not in the list', value: 'not-listed' },
    { label: 'Not relevant', value: 'not-relevant' }
  );

  const [formValues, setFormValues] = useState<Collection>(
    collection || getCollectionDefaults({ username, region: 'eu', regions })
  );
  const dispatch = useDispatch();

  /** Check that all prices are positives or 0 */
  const hasValidPrices = Object.keys(formValues?.prices).every(
    (price) => parseInt((formValues as any)?.prices[price]) >= 0
  );
  const hasValidName = !!formValues.name;
  const hasValidOpportunity = !!formValues.opportunity_id;
  const isValidForm =
    (!userAllGrants.includes('grant_HideSalesforceOpportunity') &&
      hasValidName &&
      hasValidPrices &&
      hasValidOpportunity) ||
    (userAllGrants.includes('grant_HideSalesforceOpportunity') && hasValidName && hasValidPrices);

  const isEditMode = !!collection;

  const updateFormValues = (values: Partial<Collection>) => {
    setFormValues({
      ...formValues,
      ...values,
    });
  };

  useEffect(() => {
    if (collection) {
      setFormValues(collection);
    }
  }, [collection]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateFormValues({ name: event.target.value });
  };

  const handleRegionChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const selectedRegion = event.target.value as RegionType;
    const defaults = getCollectionDefaults({ region: selectedRegion, username, regions });
    setFormValues((oldValues) => ({
      ...oldValues,
      region: selectedRegion,
      detergent_volume: defaults.detergent_volume,
      prices: defaults.prices,
      temperature: defaults.temperature,
      dose: defaults.dose,
      currency: defaults.currency,
    }));
  };

  const handleOpportunityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateFormValues({ opportunity_id: event.target.value as string });
  };

  const handleDetergentVolumeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = parseInt(event.target.value.slice(0, 9).replace(/\D/, ''));
    updateFormValues({ detergent_volume: inputValue > 0 ? inputValue : undefined });
  };

  const handleSubmit = (event: React.ChangeEvent<unknown>) => {
    event.preventDefault();
    dispatch(collection && collection.id ? updateCollection(formValues) : createCollection(formValues));
  };

  const handleCancel = () => {
    dispatch(push('/collections'));
  };

  const [nameError, setNameError] = useState<string | null>(null);
  const [opportunityError, setOpportunityError] = useState<string | null>(null);
  const [washingConditionsError, setWashingConditionsError] = useState<string | null>(null);
  const [ingredientPricesError, setIngredientPricesError] = useState<string | null>(null);

  useEffect(() => {
    if (!formValues.name) {
      setNameError('Collection name is required');
    } else {
      setNameError(null);
    }

    if (!userAllGrants.includes('grant_HideSalesforceOpportunity') && !formValues.opportunity_id) {
      setOpportunityError('Salesforce opportunity is required');
    } else {
      setOpportunityError(null);
    }

    if (!formValues.region) {
      setWashingConditionsError('Washing conditions are required');
    } else {
      setWashingConditionsError(null);
    }

    if (!hasValidPrices) {
      setIngredientPricesError('Valid ingredient prices are required');
    } else {
      setIngredientPricesError(null);
    }
  }, [formValues, userAllGrants, hasValidPrices]);

  return (
    <Layout onBack={handleCancel}>
      <Box pb={4}>
        <Typography variant="h5">{isEditMode ? 'Edit' : 'Create new'} collection</Typography>
      </Box>
      <Grid component="form" onSubmit={handleSubmit} container direction="column" spacing={5}>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Collection name</Typography>
          </Grid>

          <Grid item xs>
            <StyledOutlinedInputWithLabel
              data-cy="create-collection-name-input"
              value={formValues.name}
              handleChange={handleNameChange}
            />
          </Grid>
        </Grid>

        {!userAllGrants.includes('grant_HideSalesforceOpportunity') ? (
          <>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item container>
              <StyledGrid item xs={4}>
                <Typography variant="subtitle1">Salesforce opportunity</Typography>
                <StyledTooltip arrow placement="top" title="Opportunity: stage | name">
                  <InfoOutlinedIcon fontSize="small" />
                </StyledTooltip>
              </StyledGrid>
              <Grid item xs>
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <StyledSelectWithLabel
                      data-cy="salesforce-opportunity"
                      value={formValues.opportunity_id}
                      options={opportunitiesOptions}
                      placeholder="Choose an opportunity"
                      handleChange={handleOpportunityChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Washing conditions</Typography>
          </Grid>
          <Grid item xs>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <InputLabel id="collection-form-region-label">Region</InputLabel>
                <StyledSelectWithLabel
                  data-cy="region"
                  value={formValues.region}
                  options={regionsOptions}
                  handleChange={handleRegionChange}
                  disabled={isEditMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Annual detergent volume</Typography>
          </Grid>
          <Grid item xs>
            <Typography color="textSecondary" variant="body1">
              The volume will be used to calculate the sustainability impact
            </Typography>
            <Grid item xs={6} style={{ margin: '10px 0' }}>
              <OutlinedInputWithLabel
                data-cy="detergent-volume"
                value={formValues.detergent_volume}
                handleChange={handleDetergentVolumeChange}
                formatInput={true}
                decimalSeparator="."
                adornment={formValues.detergent_volume ? 'tonnes' : undefined}
                placeholder="Regional default in tonnes"
              />
            </Grid>
            <Grid item xs>
              <Typography color="textSecondary" variant="body2">
                *if the number is not filled in, the sustainability calculation will be based on the default regional
                detergent consumption volume
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Ingredient prices</Typography>
          </Grid>
          <IngredientsGrid item xs={12}>
            <IngredientCostTable collection={formValues} updateValues={updateFormValues} />
          </IngredientsGrid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container spacing={2} justifyContent="flex-end">
          <Grid item>
            <StyledCancel data-cy="btn-cancel-collection" color="inherit" onClick={handleCancel}>
              Cancel
            </StyledCancel>
          </Grid>
          <Grid item>
            <Button
              data-cy="btn-create-collection"
              id={isEditMode ? 'btn-update-collection' : 'btn-create-collection'}
              variant="contained"
              size="small"
              color="primary"
              type="submit"
              disabled={!isValidForm}
            >
              {isEditMode ? 'Save changes' : 'Create collection'}
            </Button>
          </Grid>
        </Grid>
        <Typography color="error">
          {nameError && <div style={{ textAlign: 'end' }}>{nameError}</div>}
          {opportunityError && <div style={{ textAlign: 'end' }}>{opportunityError}</div>}
          {washingConditionsError && <div style={{ textAlign: 'end' }}>{washingConditionsError}</div>}
          {ingredientPricesError && <div style={{ textAlign: 'end' }}>{ingredientPricesError}</div>}
        </Typography>
      </Grid>
    </Layout>
  );
};

export default CollectionForm;
