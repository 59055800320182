import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Box, Typography, styled } from '@mui/material';

import { ReactComponent as Logo } from '@novozymes-digital/laundry-lab/images/LaundryLab_logo_white_256.svg';

import NewFeaturesDialog, { getStatus } from './NewFeaturesDialog';
import AboutLaundryLabDialog from './AboutLaundryLabDialog';
import TermsOfUseDialog from './TermsOfUseDialog';
import {
  getCurrentCollectionName,
  getIsFormulationOpen,
  getUsername,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { userSignOut, goToIndex, backendData } from '@novozymes-digital/laundry-lab/store/actions/actions';
import ActionsMenu from '@novozymes-digital/laundry-lab/pages/Compare/ActionsMenu';
import ReleaseNotes from '@novozymes-digital/laundry-lab/static/ReleaseNotes.json';
import { getLocalStorageItem } from '@novozymes-digital/laundry-lab/utility/LocalStorage';
import { Status } from './NewFeaturesDialog';

import { notificationShow } from '@novozymes-digital/laundry-lab/store/actions/actions';
import {
  getNotificationMessage,
  getNotificationStatus,
  getUserGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { colors, useSnackBar, SnackColor } from '@novozymes-digital/components/';
import BehindLaundryLabDialog from './BehindLaundryLabDialog';
import NewDialog from './UI/NewDialog';

const BoxContainer = styled(Box)(() => ({
  height: '68px',
}));

const GridRoot = styled(Grid)(() => ({
  zIndex: '1300',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '68px',
  background: '#173629',
  padding: '0 20px',
  color: colors.white95,
}));

const ButtonSwitchButton = styled(Button)(() => ({
  minWidth: 0,
  backgroundColor: colors.bg1,
  '&:hover': {
    backgroundColor: '#d5d5d5',
  },
}));

const DivDot = styled('div')(() => ({
  display: 'inline-block',
  marginRight: '10px',
  width: '0.5rem',
  height: '0.5rem',
  borderRadius: '50%',
  background: '#bfe6c4',
  border: `1px solid ${colors.spaceGrey}`,
}));

const ButtonLogoButton = styled(Button)(() => ({
  width: '117px',
  height: '36px',
}));

const Navbar: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const status = getStatus();
  const lastDate = ReleaseNotes[0].date;

  const username = useSelector(getUsername);
  const activeCollectionName = useSelector(getCurrentCollectionName);
  const isFormulationsLoading = false;
  const isCalculationsLoading = false;

  const [newsOpen, setNewsOpen] = useState(lastDate !== status.close);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [behindOpen, setBehindOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const IsFormulationOpen = useSelector(getIsFormulationOpen);
  const [isLeaveCollection, setIsLeaveCollection] = useState(false);

  const handleSwitchClick = () => {
    if (IsFormulationOpen) {
      setIsLeaveCollection(IsFormulationOpen);
    } else {
      dispatch(goToIndex());
    }
  };

  const handleMenuAction = (action: string) => {
    switch (action) {
      case 'about':
        handleAbout();
        break;
      case 'behind':
        handleBehind();
        break;
      case 'terms':
        handleTerms();
        break;
      case 'news':
        handleNews();
        break;
      case 'logout':
        dispatch(userSignOut());
        break;
      case 'backend_data':
        dispatch(backendData());
        break;
      default:
        throw Error('[Navbar] Menu Action not handled.');
    }
  };

  const handleNews = () => {
    setNewsOpen(true);
  };

  const handleAbout = () => {
    setAboutOpen(true);
  };

  const handleBehind = () => {
    setBehindOpen(true);
  };

  const handleTerms = () => {
    setTermsOpen(true);
  };

  const featuresCheckMark = () => {
    const featuresLastDate = ReleaseNotes[0].date;
    const featuresStatus = getLocalStorageItem<Status>('NEW_FEATURES_STATUS');

    return featuresLastDate !== featuresStatus?.read;
  };

  const behindCheckMark = () => {
    const behindLastDate = '2023-07-17';
    const behindStatus = getLocalStorageItem<Status>('NEW_BEHIND_STATUS');

    return behindLastDate !== behindStatus?.read;
  };

  const handleGoToIndex = () => {
    if (IsFormulationOpen) {
      setIsLeaveCollection(IsFormulationOpen);
    } else {
      dispatch(goToIndex());
    }
  };

  interface IAction {
    id: string;
    name: string;
    mark?: boolean;
  }

  const actions: IAction[] = [
    { id: 'about', name: 'About LaundryLab®' },
    { id: 'behind', name: 'Behind LaundryLab®', mark: behindCheckMark() },
    { id: 'news', name: "What's new?", mark: featuresCheckMark() },
    { id: 'terms', name: 'Terms of Use' },
    { id: 'logout', name: 'Log out' },
  ];
  const userGrants = useSelector(getUserGrants);
  if (userGrants.includes('grant_CollectionFormulationsCustomStains'))
    actions.splice(actions.length - 1, 0, { id: 'backend_data', name: 'Backend data' });

  const message: string = useSelector(getNotificationMessage);
  const messageStatus: SnackColor = useSelector(getNotificationStatus);
  const snack = useSnackBar();

  useEffect(() => {
    if (!!message && message.length > 0) {
      snack.showSnackBar(message, messageStatus);
      dispatch(notificationShow({ message: '', status: messageStatus }));
    }
  }, [dispatch, message, snack, messageStatus]);

  return (
    <BoxContainer p={1}>
      <GridRoot container alignItems="center">
        <Grid item container xs={4} alignItems="center">
          <ButtonLogoButton onClick={handleGoToIndex}>
            <Logo data-cy="laundrylab-logo" />
          </ButtonLogoButton>
        </Grid>
        <Grid item xs={4}>
          {isFormulationsLoading || isCalculationsLoading ? (
            <Typography align="center">loading...</Typography>
          ) : (
            activeCollectionName && (
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item>{activeCollectionName}</Grid>
                <Grid item>
                  <ButtonSwitchButton size="small" variant="contained" onClick={handleSwitchClick} data-cy="btn-switch">
                    Switch
                  </ButtonSwitchButton>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              {username && (featuresCheckMark() || behindCheckMark()) && <DivDot />}

              {username && (
                <ActionsMenu
                  actions={actions}
                  onClickAction={(action: string) => {
                    handleMenuAction(action);
                  }}
                  toggleIcon="bottomArrow"
                  label={username}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </GridRoot>
      <AboutLaundryLabDialog open={aboutOpen} onClose={() => setAboutOpen(false)} hiddenIcon={true} />
      <BehindLaundryLabDialog open={behindOpen} onClose={() => setBehindOpen(false)} hiddenIcon={true} />
      <TermsOfUseDialog open={termsOpen} onClose={() => setTermsOpen(false)} hiddenIcon={true} />
      <NewFeaturesDialog open={newsOpen} onClose={() => setNewsOpen(false)} hiddenIcon={true} />
      <NewDialog
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        onClose={() => setIsLeaveCollection(false)}
        onConfirm={() => {
          setIsLeaveCollection(false);
          dispatch(goToIndex());
        }}
        isOpen={isLeaveCollection}
      >
        <Typography>{`You are about to leave this collection`}</Typography>
      </NewDialog>
    </BoxContainer>
  );
};

export default Navbar;
