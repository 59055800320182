import React from 'react';
import { Dialog, Grid, Typography, IconButton, styled } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { Close } from '@novozymes-digital/components';

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '300px',
  },
});

const StyledMuiDialogTitle = styled(MuiDialogTitle)(() => ({
  margin: 0,
  padding: '32px',
}));

const StyledGrid = styled(Grid)({
  paddingLeft: 64,
  paddingRight: 16,
});

const StyledIconButton = styled(IconButton)(() => ({
  color: '#9e9e9e',
}));

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <StyledMuiDialogTitle {...other}>
      <Grid container alignItems="center">
        <StyledGrid item xs>
          <Typography variant="h6" align="center">
            {children || ''}
          </Typography>
        </StyledGrid>
        {onClose && (
          <StyledIconButton aria-label="close" onClick={onClose}>
            <Close />
          </StyledIconButton>
        )}
      </Grid>
    </StyledMuiDialogTitle>
  );
};

export const DialogContent = styled(MuiDialogContent)(() => ({
  padding: '24px',
  paddingTop: '16px',
}));

export const DialogActions = styled(MuiDialogActions)(() => ({
  margin: 0,
  padding: '24px',
  background: 'white',
}));
