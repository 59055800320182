import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Ingredients } from '@novozymes-digital/laundry-lab/types/UserInput';
import { NzThemeVariables } from '@novozymes-digital/dezymer-core';

import Layout from './Layout';
import Table, { TableRow, TableCell } from './Table';

import { Formulation } from '@novozymes-digital/laundry-lab/store/types';
import {
  getCurrentCollectionRegion,
  getSelectedBaselineId,
  getFormulationsColor,
  getIngredientsByRegionAndGroup,
  getRegions,
  getUserAllGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { colors } from '@novozymes-digital/components';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';

const sortFormulations = (formulations: Formulation[], baseLineId: string) => {
  if (!baseLineId) return formulations;

  return [...formulations].sort((a, b) => {
    return a.id === baseLineId ? 1 : b.id === baseLineId ? -1 : 0;
  });
};

const sumFormulations = (formulations: Formulation[]): Record<string, number> => {
  const totalFormulations: Record<string, number> = {};
  formulations.map((formulation: Formulation) => {
    totalFormulations[formulation.id] =
      Math.round(
        (formulation?.las +
          formulation?.aes +
          formulation?.aeo +
          formulation?.amplifyprime100l +
          formulation?.citrate +
          formulation?.lipexevity200l +
          formulation?.mannaway200l +
          formulation?.progressuno100l +
          formulation?.liquanase35l +
          formulation?.soap +
          formulation?.lipex100t +
          formulation?.mannaway4t +
          formulation?.savinase12t +
          formulation?.stainzymeplus12t +
          formulation?.celluclean4500t +
          formulation?.sodiumcarbonate +
          formulation?.xpect1000l +
          formulation?.silicate +
          formulation?.savinase16t +
          formulation?.xpect1000t +
          formulation?.medleyCore200l +
          formulation?.medleyBoost300l +
          formulation?.medleyFlex300l +
          formulation?.medleyBright200l +
          formulation?.medleyAdvance300l +
          formulation?.medleyEssential200l +
          formulation?.medleySelect300l +
          formulation?.medleyPure300l +
          formulation?.medleyOptiwash1750s +
          formulation?.medleyOptiwash3500s +
          formulation?.medleyOptiwash400splus +
          formulation?.medleyAdvance200t +
          formulation?.medleyBrilliant400l) *
          100
      ) / 100;
  });
  return totalFormulations;
};

interface Props {
  formulations: Formulation[];
}

const Formulations: React.FunctionComponent<Props> = ({ formulations }: Props) => {
  const region = useSelector(getCurrentCollectionRegion);
  const dose_scale = useSelector(getRegions)[region].dose_scale;
  const detergent_format = useSelector(getRegions)[region].detergent_format;
  const baselineId = useSelector(getSelectedBaselineId) || '';
  const userAllGrants = useSelector(getUserAllGrants);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

  const sortedFormulations = useMemo(() => sortFormulations(formulations, baselineId), [formulations, baselineId]);
  const totalFormulations = useMemo(() => sumFormulations(formulations), [formulations]);

  const layout = useSelector(getIngredientsByRegionAndGroup)[region] || [];
  const formulationsColor = useSelector(getFormulationsColor);
  const labels = sortedFormulations.map((formulation) => ({
    label: formulation.name + (!!baselineId && formulation.id === baselineId ? ' (baseline)' : ''),
    isBaseline: !!baselineId && formulation.id === baselineId,
    customColor: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color,
  }));

  return (
    <Layout data-cy="formulations-data-panel" title="Formulations" id="formulations">
      <NzThemeVariables />
      <Table labels={labels} labelsOffset={2}>
        {layout.map(({ group_label, ingredients }) =>
          ingredients.map((value, index) => {
            const ingredientName = Object.keys(value)[0];

            if (ingredientName === 'medleyBrilliant400l' && !grantGlobalCosmed) {
              return null;
            }

            return (
              <TableRow key={index}>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    borderTop: index === 0 ? '1px solid ' + colors.spaceGreyB : '',
                    borderBottom: index === ingredients.length - 1 ? '1px solid ' + colors.spaceGreyB : '',
                  }}
                >
                  {index === 0 && group_label}
                </TableCell>
                <TableCell
                  style={{
                    borderTop: index === 0 ? '1px solid ' + colors.spaceGreyB : '',
                    borderBottom: index === ingredients.length - 1 ? '1px solid ' + colors.spaceGreyB : '',
                  }}
                >
                  {Object.values(value)[0]}
                </TableCell>
                {sortedFormulations.map(({ id, ...values }, i) => (
                  <TableCell
                    key={`${id}-${i}`}
                    style={{
                      borderTop: index === 0 ? '1px solid ' + colors.spaceGreyB : '',
                      borderBottom: index === ingredients.length - 1 ? '1px solid ' + colors.spaceGreyB : '',
                    }}
                    align="left"
                  >
                    {group_label === 'Enzymes' || group_label === 'Fillers'
                      ? trunc(values[Object.keys(value)[0] as keyof Ingredients], 2)
                      : trunc(values[Object.keys(value)[0] as keyof Ingredients], 1)}
                    %
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        )}
        <TableRow>
          <TableCell style={{ fontWeight: 'bold', borderBottom: `1px solid ${colors.spaceGreyB}` }}>Total</TableCell>
          <TableCell style={{ borderBottom: `1px solid ${colors.spaceGreyB}` }}>
            {detergent_format[0] == 'Powder' ? 'all ingredients (excl. fillers)' : 'all ingredients (excl. water)'}
          </TableCell>
          {sortedFormulations.map((formulation) => (
            <TableCell
              style={{ borderBottom: `1px solid ${colors.spaceGreyB}` }}
              key={`${formulation.id}-total`}
              align="left"
            >
              {totalFormulations[formulation.id]} %
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={{ fontWeight: 'bold' }}>Conditions</TableCell>
          <TableCell>Temperature</TableCell>
          {sortedFormulations.map((formulation) => (
            <TableCell key={`${formulation.id}-temperature`} align="left">
              {formulation.temperature} °C
            </TableCell>
          ))}
        </TableRow>
        {['afr', 'me', 'ind', 'sea'].includes(region) && (
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Water hardness</TableCell>
            {sortedFormulations.map((formulation) => (
              <TableCell key={`${formulation.id}-water_hardness`} align="left">
                {formulation.water_hardness} ppm
              </TableCell>
            ))}
          </TableRow>
        )}
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Dosage</TableCell>
          {sortedFormulations.map((formulation) => (
            <TableCell key={`${formulation.id}-dose`} align="left">
              {formulation.dose} {dose_scale}
            </TableCell>
          ))}
        </TableRow>
      </Table>
    </Layout>
  );
};

export default Formulations;
