import React, { useState } from 'react';
import { Box, Button, IconButton, Typography, Tooltip, styled } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';

import { StyledDialog, DialogTitle, DialogActions, DialogContent } from './UI/Dialog';
import { formatFullDate } from '@novozymes-digital/laundry-lab/utility/Date';
import ReleaseNotes from '@novozymes-digital/laundry-lab/static/ReleaseNotes.json';
import { colors } from '@novozymes-digital/components';
import { getLocalStorageItem, setLocalStorageItem } from '@novozymes-digital/laundry-lab/utility/LocalStorage';

export interface Status {
  read: string;
  close: string;
}

export function getStatus(): Status {
  try {
    return (getLocalStorageItem('NEW_FEATURES_STATUS') as unknown as Status) || '';
  } catch (e) {
    return { read: '', close: '' };
  }
}

function setStatus(status: Status) {
  try {
    setLocalStorageItem('NEW_FEATURES_STATUS', status);
  } catch (e) {
    // empty
  }
}

const StyledIconButton = styled(IconButton)(() => ({
  position: 'relative',
  color: colors.white95,
}));

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '12px',
  height: '12px',
  background: '#bfe6c4',
  border: `1px solid #f5f5f5`,
  borderRadius: '8px',
}));

interface Props {
  open?: boolean;
  onClose?: () => void;
  hiddenIcon?: boolean;
}

const NewFeaturesDialog: React.FC<Props> = ({ open = false, onClose, hiddenIcon = false }: Props) => {
  const lastDate = ReleaseNotes[0].date;
  const status = getStatus();
  const [isOpen, setOpen] = useState(false);
  const toggleDialog = () => setOpen(!isOpen);
  const handleClose = () => {
    if (!open) toggleDialog();
    setStatus({ ...status, close: lastDate });
    if (onClose) onClose();
  };

  const handleRead = () => {
    handleClose();
    setStatus({ read: lastDate, close: lastDate });
  };

  return (
    <>
      {!hiddenIcon && (
        <Tooltip arrow title={status.read !== lastDate ? 'New features added to LaundryLab' : ''}>
          <StyledIconButton onClick={handleClose}>
            <HelpOutline color="inherit" />
            {status.read !== lastDate && <StyledBox />}
          </StyledIconButton>
        </Tooltip>
      )}
      <StyledDialog
        open={isOpen || open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            maxWidth: 960,
          },
        }}
      >
        <DialogTitle onClose={handleClose}>New in this LaundryLab release</DialogTitle>
        <DialogContent>
          {ReleaseNotes.map(({ date, title, content }, index) => (
            <Box mb={index < ReleaseNotes.length - 1 ? 5 : 0} key={date}>
              <Typography variant="body2">{formatFullDate(date)}</Typography>
              <Box mb={1}>
                <Typography variant="h3">{title}</Typography>
              </Box>
              {content.map((p) => (
                <Typography gutterBottom variant="body1" key={p}>
                  {p}
                </Typography>
              ))}
            </Box>
          ))}
        </DialogContent>
        <DialogActions style={{ margin: '0', padding: '12px 24px', background: 'white' }}>
          <Button
            data-cy="new-features-ok-button"
            variant="contained"
            size="small"
            color="primary"
            onClick={handleRead}
          >
            OK
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default NewFeaturesDialog;
