import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { BASELINE_LABEL, tooltipSettings } from '@novozymes-digital/laundry-lab/static/Constants';
import { colors as nzColors } from '@novozymes-digital/components';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Tooltip, SubTitle } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, Tooltip, SubTitle);
import { isEqual } from 'lodash';
import { colors as colorsNovozymes } from '@novozymes-digital/components';
import { getUserAllGrants } from '@novozymes-digital/laundry-lab/store/selectors';
import { useSelector } from 'react-redux';

export const baselineChartSettings = {
  // label: BASELINE_LABEL,
  borderWidth: {
    top: 0,
    right: 1,
    bottom: 0,
    left: 0,
  },
  maxBarThickness: 50,
  borderColor: nzColors.black80,
  barPercentage: 1.0,
  categoryPercentage: 0.8,
};

export const commonBarChartSettings = {
  borderWidth: 1,
  barPercentage: 0.8,
  categoryPercentage: 0.7,
  maxBarThickness: 35,
  borderColor: nzColors.dataGrey,
};

interface CustomChartData {
  datasets: Array<any & { baseline?: boolean }>;
  labels?: string[];
  subLabels?: string[];
}

export type GetColorFunc = ({ label, index }: { label?: string; index?: number }) => string;

interface Props {
  data: CustomChartData;
  subtitle?: string;
  noLegend?: boolean;
  getColor: GetColorFunc;
}

const addStyleToDataset = (getColor: GetColorFunc) => (dataset: any, index: number) => {
  const baseStyle = dataset.baseline ? baselineChartSettings : commonBarChartSettings;
  const backgroundColor = dataset.baseline ? 'transparent' : getColor({ label: dataset.label, index });
  return { ...dataset, ...baseStyle, backgroundColor };
};

export const colors = [
  '#bd75b4',
  '#e8cfe5',
  '#a6bcc1',
  '#d2dfe2',
  '#97c2ed',
  '#c6ddf4',
  '#c794a2',
  '#d1c2c6',
  '#ffb405',
  '#ffd982',
];
const getColorDefault: GetColorFunc = ({ index }) => {
  if (typeof index !== 'number') {
    return '';
  }
  if (index > colors.length) {
    return colors[colors.length - 1];
  }
  return colors[index];
};

const LABEL_LEFT_WIDTH = 150;

const ChartLegend = ({
  labels,
  hasBaseline,
  getColor = getColorDefault,
  baselineLabel,
}: {
  labels: Array<string | undefined>;
  hasBaseline: boolean;
  getColor: GetColorFunc;
  baselineLabel: string;
}) => {
  return (
    <Box mb={2} ml={1} style={{ paddingLeft: LABEL_LEFT_WIDTH }}>
      <Grid container spacing={3}>
        {labels.map((label: any, index: number) => (
          <Grid item xs={2} key={label + index}>
            <Grid container wrap="nowrap">
              <Box bgcolor={getColor({ label })} width={6} mr={1} flexShrink={0} borderRadius={8}></Box>
              <Typography variant="caption">{label}</Typography>
            </Grid>
          </Grid>
        ))}
        {hasBaseline && (
          <Grid item xs={4} container alignItems="stretch">
            <Box bgcolor="black" width={2} mr={1}></Box>
            <Typography variant="caption">{baselineLabel ? baselineLabel : BASELINE_LABEL}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const Chart: React.FunctionComponent<Props> = ({ data, subtitle, noLegend, getColor }: Props) => {
  const userAllGrants = useSelector(getUserAllGrants);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

  if (!data || !data.labels || !data.labels.length || !data.datasets) return null;

  let hasBaseline = false;
  let baselineLabel = '';

  const baseline = data.datasets.find((dataset) => dataset.baseline);
  hasBaseline = !!baseline;
  if (baseline) baselineLabel = baseline?.label as string;

  const styledDatasets = data.datasets.map(addStyleToDataset(getColor));
  const dataLabels = data.datasets.filter((dataset) => !dataset.baseline).map((dataset) => dataset.label);

  const lineHeight = styledDatasets.length > 2 ? 80 : 60;
  const height = data.labels.length * lineHeight + 28 + 40;

  const chartData = {
    ...data,
    datasets: styledDatasets,
  };

  // Custom tooltip to use instead of the default one, because it get's cut off if there are too many labels

  const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = colorsNovozymes.bg2;
      tooltipEl.style.borderRadius = '16px';
      tooltipEl.style.color = colorsNovozymes.black80;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.style.minWidth = '200px';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body || [];

    const table = document.createElement('table');
    table.style.margin = '0px';

    // Add title
    const tableHead = document.createElement('thead');
    titleLines.forEach((title: string) => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = '0';

      const th = document.createElement('th');
      th.style.borderWidth = '0';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });
    table.appendChild(tableHead);

    // Add body
    const tableBody = document.createElement('tbody');
    const seenBaselines = new Set(); // Track seen Baseline entries

    bodyLines.forEach((body: any, i: number) => {
      const dataset = context.chart.data.datasets[i];
      if (dataset) {
        const isBaseline = dataset.baseline;

        if (isBaseline && seenBaselines.has('Baseline')) {
          // Skip if Baseline is already seen
          return;
        }

        if (isBaseline) {
          seenBaselines.add('Baseline');
        }

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';

        const td = document.createElement('td');
        td.style.borderWidth = '0';

        const colors = dataset.backgroundColor || tooltip.labelColors[i];
        const span = document.createElement('span');
        span.style.background = colors;
        span.style.borderColor = colors;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const text = document.createTextNode(body.lines.join(': '));
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      }
    });

    table.appendChild(tableBody);

    // Clear existing tooltip content
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    // Append the new content to the tooltip
    tooltipEl.appendChild(table);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  return (
    <Box>
      {!noLegend && (
        <ChartLegend labels={dataLabels} hasBaseline={hasBaseline} getColor={getColor} baselineLabel={baselineLabel} />
      )}
      <Grid container spacing={2}>
        <Grid item style={{ width: LABEL_LEFT_WIDTH }}>
          <Grid container direction="column">
            {data.labels.map((label, index) => (
              <Grid
                key={`${label}${index}`}
                item
                container
                direction="column"
                justifyContent="center"
                style={{ height: lineHeight }}
              >
                <Typography variant={data.subLabels ? 'caption' : 'body2'} align="left">
                  {label}
                </Typography>
                {data.subLabels && data.subLabels[index] && (
                  <Typography variant="caption" align="left">
                    {data.subLabels[index]}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item style={{ width: 'calc(100% - 150px)' }}>
          <div style={{ height }}>
            <Bar
              redraw={false}
              data={chartData}
              options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  //duration: 0,
                },
                layout: {
                  padding: {
                    bottom: 40,
                  },
                },
                interaction: {
                  mode: 'index',
                  axis: 'y',
                },
                scales: {
                  y: {
                    ticks: {
                      display: false,
                    },
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                      display: false,
                    },
                  },
                  x: {
                    ticks: {
                      callback: (value: any) => {
                        return value;
                      },
                    },
                    stacked: false,
                    beginAtZero: true,
                  },
                },
                plugins: {
                  subtitle: {
                    display: true,
                    text: subtitle,
                    position: 'bottom',
                  },
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: false,
                    external: externalTooltipHandler,
                    position: 'average',
                    //mode: 'index',
                    ...tooltipSettings,
                    /*filter: function ({ datasetIndex }: { datasetIndex: number }, data: any) {
                      return (
                        !hasBaseline ||
                        (data?.datasets && !data?.datasets[datasetIndex]?.baseline) ||
                        datasetIndex === 0
                      );
                    },*/
                    callbacks: {
                      label: function (context) {
                        const { dataIndex, dataset } = context;
                        const { label } = dataset;
                        const value = (dataset as any).values?.[dataIndex] ?? dataset.data[dataIndex];

                        // Check if the label is 'Medley Brilliant 400 L' and grantGlobalCosmed is false
                        if (!(label === 'Medley Brilliant 400 L' && !grantGlobalCosmed)) {
                          return `${label}: ${value}`;
                        }

                        // If the condition is not met, return an empty string
                        return '';
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Chart, isEqual);
