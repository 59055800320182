import actionTypes from './actionTypes';
import {
  ActionCreator,
  Collection,
  EcolabelResult,
  Formulation,
  RegionType,
  StainRemovalResult,
  SustainabilityResult,
  StainRegions,
  StainGroupCreate,
  StainGroupCustom,
  Opportunities,
} from '../types';
import {
  BlendsInputLayoutsType,
  IngredientsInputLayoutsType,
  Region,
} from '@novozymes-digital/laundry-lab/static/Constants';
import { SnackColor } from '@novozymes-digital/components';

/*  
/
/ AUTH ACTIONS
/
*/

// TODO: Add user type
export const userAuthenticated: ActionCreator<boolean> = (user) => ({
  type: actionTypes.USER_AUTHENTICATED,
  payload: {
    user,
  },
});

export const userAuthorized: ActionCreator<boolean> = (isAuthorized) => ({
  type: actionTypes.USER_AUTHORIZED,
  payload: {
    isAuthorized,
  },
});

export const environment: ActionCreator<string> = (environment) => ({
  type: actionTypes.ENVIRONMENT,
  payload: {
    environment,
  },
});

export const userSignOut: ActionCreator = () => ({
  type: actionTypes.USER_SIGN_OUT,
});

export const backendData: ActionCreator = () => ({
  type: actionTypes.BACKEND_DATA,
});

export const userSignInFailed: ActionCreator<string> = (message) => ({
  type: actionTypes.USER_SIGN_IN_FAILED,
  payload: {
    message,
  },
});

/*  
/
/ APP ACTIONS
/
*/
export const initApp: ActionCreator = () => ({
  type: actionTypes.APP_INIT,
});
export const signIn: ActionCreator = () => ({
  type: actionTypes.USER_SIGN_IN,
});
export const initLogin: ActionCreator = () => ({
  type: actionTypes.LOGIN_INIT,
});

export const fetchUserCollections: ActionCreator = () => ({
  type: actionTypes.FETCH_USER_COLLECTIONS,
});

export const fetchUserCollectionsResult: ActionCreator<{ error?: Error; collections?: Collection[] }> = ({
  error,
  collections,
}: {
  error?: Error;
  collections?: Collection[];
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.FETCH_USER_COLLECTIONS_RESULT,
    payload: hasError
      ? error
      : {
          collections,
        },
    error: hasError,
  };
};

export const selectCollection: ActionCreator<number> = (collectionId) => ({
  type: actionTypes.SELECT_COLLECTION,
  payload: {
    collectionId,
  },
});

export const createCollection: ActionCreator<Collection> = (collection) => ({
  type: actionTypes.CREATE_COLLECTION,
  payload: {
    collection,
  },
});

export const createCollectionResult: ActionCreator<{ error?: Error; collection?: Collection }> = ({
  error,
  collection,
}) => {
  const hasError = !!error;

  return {
    type: actionTypes.CREATE_COLLECTION_RESULT,
    payload: hasError
      ? error
      : {
          collection,
        },
    error: hasError,
  };
};

export const updateCollection: ActionCreator<Collection> = (collection) => ({
  type: actionTypes.UPDATE_COLLECTION,
  payload: {
    collection,
  },
});

export const updateCollectionResult: ActionCreator<{ error?: Error; collection?: Collection }> = ({
  error,
  collection,
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.UPDATE_COLLECTION_RESULT,
    payload: hasError
      ? error
      : {
          collection,
        },
    error: hasError,
  };
};
export const pinCollection: ActionCreator<{ collectionId: number; isCurrentlyPinned: boolean }> = ({
  collectionId,
  isCurrentlyPinned,
}) => ({
  type: actionTypes.PIN_COLLECTION,
  payload: {
    collectionId,
    isCurrentlyPinned,
  },
});

export const pinCollectionResult: ActionCreator<{ error?: Error; collectionId?: number }> = ({
  error,
  collectionId,
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.PIN_COLLECTION_RESULT,
    payload: hasError
      ? error
      : {
          collectionId,
        },
    error: hasError,
  };
};

export const deleteCollection: ActionCreator<{ collectionId: number }> = ({ collectionId }) => ({
  type: actionTypes.DELETE_COLLECTION,
  payload: {
    collectionId,
  },
});

export const deleteCollectionResult: ActionCreator<{ error?: Error }> = ({ error }) => {
  const hasError = !!error;
  return {
    type: actionTypes.DELETE_COLLECTION_RESULT,
    payload: hasError ? error : {},
    error: hasError,
  };
};

export const cloneCollection: ActionCreator<{ collectionId: number }> = ({ collectionId }) => ({
  type: actionTypes.CLONE_COLLECTION,
  payload: {
    collectionId,
  },
});

export const cloneCollectionResult: ActionCreator<{ error?: Error }> = ({ error }) => {
  const hasError = !!error;
  return {
    type: actionTypes.CLONE_COLLECTION_RESULT,
    payload: hasError ? error : {},
    error: hasError,
  };
};

export const sendCollection: ActionCreator<{ collectionId: number; newUser: string }> = ({
  collectionId,
  newUser,
}) => ({
  type: actionTypes.SEND_COLLECTION,
  payload: {
    collectionId,
    newUser,
  },
});

export const sendCollectionResult: ActionCreator<{ error?: Error }> = ({ error }) => {
  const hasError = !!error;
  return {
    type: actionTypes.SEND_COLLECTION_RESULT,
    payload: hasError ? error : {},
    error: hasError,
  };
};

export const cloneFormulation: ActionCreator<{ formulationId: string; collectionId: number }> = ({
  formulationId,
  collectionId,
}) => ({
  type: actionTypes.CLONE_FORMULATION,
  payload: {
    formulationId,
    collectionId,
  },
});

export const cloneFormulationResult: ActionCreator<{ error?: Error }> = ({ error }) => {
  const hasError = !!error;
  return {
    type: actionTypes.CLONE_FORMULATION_RESULT,
    payload: hasError ? error : {},
    error: hasError,
  };
};

export const deleteFormulation: ActionCreator<{ formulationId: string; collectionId: number }> = ({
  formulationId,
  collectionId,
}) => ({
  type: actionTypes.DELETE_FORMULATION,
  payload: {
    formulationId,
    collectionId,
  },
});

export const deleteFormulationResult: ActionCreator<{ error?: Error; deletedFormulationId?: string }> = ({
  error,
  deletedFormulationId,
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.DELETE_FORMULATION_RESULT,
    payload: hasError ? error : { deletedFormulationId },
    error: hasError,
  };
};

export const fetchFormulations: ActionCreator<number> = (collectionId) => ({
  type: actionTypes.FETCH_FORMULATIONS,
  payload: {
    collectionId,
  },
});

export const fetchFormulationsResult: ActionCreator<{ error?: Error; formulations?: Formulation[] }> = ({
  error,
  formulations,
}: {
  error?: Error;
  formulations?: Formulation[];
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.FETCH_FORMULATIONS_RESULT,
    payload: hasError
      ? error
      : {
          formulations,
        },
    error: hasError,
  };
};

export const toggleSelectFormulation: ActionCreator<{ formulationId: string; isSelected: boolean }> = ({
  formulationId,
  isSelected,
}) => ({
  type: actionTypes.TOGGLE_SELECT_FORMULATION,
  payload: {
    formulationId,
    isSelected,
  },
});

export const goToIndex: ActionCreator = () => ({
  type: actionTypes.GO_TO_INDEX,
});

export const calculateFormulation: ActionCreator<{ formulationId: string }> = ({ formulationId }) => ({
  type: actionTypes.CALCULATE_FORMULATION,
  payload: {
    formulationId,
  },
});

export const calculateStainRemoval: ActionCreator<{ formulationData: Formulation; newCustomStain?: any }> = ({
  formulationData,
  newCustomStain,
}) => ({
  type: actionTypes.CALCULATE_STAIN_REMOVAL,
  payload: {
    formulationData,
    newCustomStain,
  },
});
export const calculateSustainability: ActionCreator<{ formulationData: Formulation; detergent_volume?: any }> = ({
  formulationData,
  detergent_volume,
}) => ({
  type: actionTypes.CALCULATE_SUSTAINABILITY,
  payload: {
    formulationData,
    detergent_volume,
  },
});
export const calculateEcoLabel: ActionCreator<{ formulationData: Formulation; region: RegionType }> = ({
  formulationData,
  region,
}) => ({
  type: actionTypes.CALCULATE_ECO_LABEL,
  payload: {
    formulationData,
    region,
  },
});

export const calculateStainRemovalResult: ActionCreator<{
  error?: Error;
  formulationId: string;
  stainRemovalResult?: StainRemovalResult;
}> = ({ error, formulationId, stainRemovalResult }) => {
  const hasError = !!error;
  return {
    type: actionTypes.CALCULATE_STAIN_REMOVAL_RESULT,
    payload: hasError
      ? error
      : {
          formulationId,
          stainRemovalResult,
        },
    error: hasError,
  };
};
export const calculateSustainabilityResult: ActionCreator<{
  error?: Error;
  formulationId: string;
  sustainabilityResult?: SustainabilityResult;
}> = ({ error, formulationId, sustainabilityResult }) => {
  const hasError = !!error;
  return {
    type: actionTypes.CALCULATE_SUSTAINABILITY_RESULT,
    payload: hasError
      ? error
      : {
          formulationId,
          sustainabilityResult,
        },
    error: hasError,
  };
};
export const calculateEcoLabelResult: ActionCreator<{
  error?: Error;
  formulationId: string;
  ecolabelResult?: EcolabelResult;
}> = ({ error, formulationId, ecolabelResult }) => {
  const hasError = !!error;
  return {
    type: actionTypes.CALCULATE_ECO_LABEL_RESULT,
    payload: hasError
      ? error
      : {
          formulationId,
          ecolabelResult,
        },
    error: hasError,
  };
};

export const setBaseline: ActionCreator<{ formulationId: string }> = ({ formulationId }) => ({
  type: actionTypes.SET_BASELINE,
  payload: {
    formulationId,
  },
});

export const openNewFormulation: ActionCreator = () => ({
  type: actionTypes.OPEN_NEW_FORMULATION,
});

export const closeNewFormulation: ActionCreator = () => ({
  type: actionTypes.CLOSE_NEW_FORMULATION,
});

export const updateNewFormulation: ActionCreator<{ formulationData: Formulation | undefined; region: RegionType }> = ({
  formulationData,
  region,
}) => ({
  type: actionTypes.UPDATE_NEW_FORMULATION,
  payload: {
    formulationData,
    region,
  },
});

export const openEditFormulation: ActionCreator<{ formulationId: string; isSelected: boolean }> = ({
  formulationId,
  isSelected,
}) => ({
  type: actionTypes.OPEN_EDIT_FORMULATION,
  payload: {
    formulationId,
    isSelected,
  },
});

export const closeEditFormulation: ActionCreator = () => ({
  type: actionTypes.CLOSE_EDIT_FORMULATION,
});

export const updateFormulation: ActionCreator<{ formulation: Formulation | undefined }> = ({ formulation }) => ({
  type: actionTypes.UPDATE_FORMULATION,
  payload: {
    formulation,
  },
});

export const updateFormulationResult: ActionCreator<{ error?: Error; formulation?: Formulation }> = ({
  error,
  formulation,
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.UPDATE_FORMULATION_RESULT,
    payload: hasError
      ? error
      : {
          formulation,
        },
    error: hasError,
  };
};

export const createFormulation: ActionCreator<{ formulationData: Formulation }> = ({ formulationData }) => ({
  type: actionTypes.CREATE_FORMULATION,
  payload: {
    formulationData,
  },
});

export const createFormulationResult: ActionCreator<{ error?: Error }> = ({ error }) => ({
  type: actionTypes.CREATE_FORMULATION_RESULT,
  payload: error,
  error: !!error,
});

export const exportExcel: ActionCreator<{ collectionId: number; formulationIds: string[] }> = ({
  collectionId,
  formulationIds,
}) => ({
  type: actionTypes.EXPORT_EXCEL,
  payload: {
    collectionId,
    formulationIds,
  },
});

export const exportExcelResult: ActionCreator<{ error?: Error }> = ({ error }) => ({
  type: actionTypes.EXPORT_EXCEL_RESULT,
  payload: error,
  error: !!error,
});

export const responseCurve: ActionCreator<{ collectionId: number; formulationIds: string[] }> = ({
  collectionId,
  formulationIds,
}) => ({
  type: actionTypes.RESPONSE_CURVE,
  payload: {
    collectionId,
    formulationIds,
  },
});

export const getStainGroupsResult: ActionCreator<{
  stainGroups: Record<RegionType, Record<string, string>> | null;
  error?: Error;
}> = ({ stainGroups, error }) => ({
  type: actionTypes.GET_STAIN_GROUPS_RESULT,
  payload: error ? error : { stainGroups },
  error: !!error,
});

export const getIngredientsResult: ActionCreator<{
  ingredients: IngredientsInputLayoutsType | null;
  error?: Error;
}> = ({ ingredients, error }) => ({
  type: actionTypes.GET_INGREDIENTS_RESULT,
  payload: error
    ? error
    : {
        ingredients,
      },
  error: !!error,
});

export const getBlendsResult: ActionCreator<{
  blends: BlendsInputLayoutsType | null;
  error?: Error;
}> = ({ blends, error }) => ({
  type: actionTypes.GET_BLENDS_RESULT,
  payload: error
    ? error
    : {
        blends,
      },
  error: !!error,
});

export const getRevisionResult: ActionCreator<{
  revision: number | null;
  error?: Error;
}> = ({ revision, error }) => ({
  type: actionTypes.GET_REVISION_RESULT,
  payload: error ? error : revision,
  error: !!error,
});

export const getOpportunitiesResult: ActionCreator<{
  opportunities: Opportunities | null;
  error?: Error;
}> = ({ opportunities, error }) => ({
  type: actionTypes.GET_OPPORTUNITIES_RESULT,
  payload: error ? error : { opportunities },
  error: !!error,
});

export const getRegionsResult: ActionCreator<{
  regions: Region | null;
  error?: Error;
}> = ({ regions, error }) => ({
  type: actionTypes.GET_REGIONS_RESULT,
  payload: error ? error : { regions },
  error: !!error,
});

export const getStainsRegionResult: ActionCreator<{
  stainsRegions: StainRegions | null;
  error?: Error;
}> = ({ stainsRegions, error }) => ({
  type: actionTypes.GET_STAINS_REGIONS_RESULT,
  payload: error ? error : { stainsRegions },
  error: !!error,
});

export const notificationShow: ActionCreator<{ message: string | null; status?: SnackColor }> = ({
  message,
  status,
}) => ({
  type: actionTypes.NOTIFICATION,
  payload: { message, status },
});

export const createCustomStainGroup: ActionCreator<{ stainGroupData: StainGroupCreate }> = ({ stainGroupData }) => ({
  type: actionTypes.CREATE_CUSTOM_STAIN_GROUP,
  payload: {
    stainGroupData,
  },
});

export const setStainGroup: ActionCreator<{ stainGroupData: StainGroupCreate }> = ({ stainGroupData }) => ({
  type: actionTypes.SET_CUSTOM_STAIN_GROUP,
  payload: {
    stainGroupData,
  },
});

export const getCustomStainGroup: ActionCreator = () => ({
  type: actionTypes.GET_STAIN_CUSTOM_GROUPS,
  payload: {},
});

export const getStainCustomGroupsResult: ActionCreator<{
  stainCustomGroups: StainGroupCustom | null;
  error?: Error;
}> = ({ stainCustomGroups, error }) => ({
  type: actionTypes.GET_STAIN_CUSTOM_GROUPS_RESULT,
  payload: error ? error : { stainCustomGroups },
  error: !!error,
});

export const deleteStainCustomGroup: ActionCreator<{ id: number; collection_id: number | undefined }> = ({
  id,
  collection_id,
}) => ({
  type: actionTypes.DELETE_CUSTOM_STAIN_GROUP,
  payload: {
    id,
    collection_id,
  },
});

export const deleteStainCustomGroupResult: ActionCreator<{ error?: Error }> = ({ error }) => {
  const hasError = !!error;
  return {
    type: actionTypes.DELETE_CUSTOM_STAIN_GROUP_RESULT,
    payload: hasError ? error : {},
    error: hasError,
  };
};

export const voidResult: ActionCreator<{ error?: Error; data?: any }> = ({
  error,
  data,
}: {
  error?: Error;
  data?: any;
}) => {
  const hasError = !!error;
  return {
    type: actionTypes.VOID_RESULT,
    payload: hasError
      ? error
      : {
          data,
        },
    error: hasError,
  };
};

export const sendFeedback: ActionCreator<{ message: string }> = ({ message }) => ({
  type: actionTypes.SEND_FEEDBACK,
  payload: {
    message,
  },
});

export const sendFeedbackResult: ActionCreator<{ error?: Error }> = ({ error }) => {
  const hasError = !!error;
  return {
    type: actionTypes.SEND_FEEDBACK_RESULT,
    payload: hasError ? error : {},
    error: hasError,
  };
};
