import React, { useState } from 'react';
import { IconButton, Button, Typography, Box, Link, styled } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { StyledDialog, DialogTitle, DialogActions, DialogContent } from './UI/Dialog';
import { colors } from '@novozymes-digital/components';

const StyledIconButton = styled(IconButton)(() => ({
  color: colors.white95,
  marginRight: '-16px',
}));

const StyledBox = styled(Box)(() => ({
  padding: '32px',

  '& > p': {
    paddingBottom: '22px',
  },
}));

interface Props {
  open?: boolean;
  onClose?: () => void;
  hiddenIcon?: boolean;
}

const TermsOfUseDialog: React.FC<Props> = ({ open = false, onClose, hiddenIcon = false }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const toggleDialog = () => {
    open && onClose ? onClose() : setOpen(!isOpen);
  };

  return (
    <>
      {!hiddenIcon && (
        <StyledIconButton onClick={toggleDialog}>
          <InfoOutlined color="inherit" />
        </StyledIconButton>
      )}
      <StyledDialog
        open={isOpen || open}
        onClose={toggleDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        // maxWidth={'md'}
        PaperProps={{
          style: {
            maxWidth: 960,
          },
        }}
      >
        <DialogTitle onClose={toggleDialog}>LaundryLab® Terms of Use</DialogTitle>
        <DialogContent>
          <StyledBox>
            <Typography variant="h3">PURPOSE OF THE WEBSITE</Typography>

            <Typography variant="body1" component="p">
              The purpose of the Website is strictly commercial, and as such, it is meant to further Novozymes’ business
              by the sales of enzymes to formulators of laundry detergents.
            </Typography>
            <Typography variant="body1" component="p">
              This is done by providing detergent formulators insights into the predicted performance on stain removal
              of their detergent formulation. The availability of such information should help detergent formulators
              significantly reduce the number of actual wash trials needed to refine and develop the final formulation.
              This Website lowers therefore the cost of formulation development and frees up resources for faster
              innovation.
            </Typography>
            <Typography variant="body1" component="p">
              The predictions provided by the Website are indicative and are meant as formulation guidance. They should
              not substitute all lab work. Contact Novonesis for further formulation advice involving enzymes, before
              conducting physical trials.
            </Typography>
            <Typography variant="body1" component="p">
              Please note that LaundryLab® is only made available in English. Be aware that any translation of the tool
              by third party add-ons is not an authorized translation and can therefore lead to inaccuracies.
            </Typography>

            <Typography variant="h3">Contact</Typography>
            <Typography variant="body1" component="p">
              If you have any questions or comments with respect to these Terms of Use, or other Information on the
              Website, please do not hesitate to contact Novonesis through{' '}
              <Link underline="hover" href="mailto:yse@novonesis.com">
                yse@novonesis.com
              </Link>
              .
            </Typography>

            <Typography variant="h3">INPUT DATA</Typography>

            <Typography variant="body1" component="p">
              When you use this Website, you are requested to provide input regarding the following input data in
              relation to your company (“Input Data”):
              <ol>
                <li>
                  Ingredients used: In order for the algorithm to provide the best guidance/recommendation, on
                  formulation composition, you need to specify which ingredients you want to use in your (re)formulation
                  and at which concentrations{' '}
                </li>
                <li>
                  Ingredient prices: In order for the algorithm to provide the best guidance/recommendation on
                  formulation cost, you need to input prices for the used ingredients{' '}
                </li>
                <li>
                  Currency and unit quotes: In order for the algorithm to provide the most relevant
                  guidance/recommendation, you need to specify what currency and weight unit you are using{' '}
                </li>
                <li>
                  Region, wash temperature and dosage: In order for the algorithm to combine all the other inputs
                  together and provide meaningful guidance/recommendation{' '}
                </li>
              </ol>
            </Typography>
            <Typography variant="body1" component="p">
              The Input data, when entered, is used within the Novonesis group as an input for the prediction algorithm
              to deliver the recommendations on this Website to improve the functionality of the algorithm, potentially
              jointly with external advisors subject to appropriate confidentiality obligations. The Input data is also
              used for marketing intelligence purposes so that the tool can be further developed to follow the latest
              market trends and dynamics.
            </Typography>
            <Typography variant="body1" component="p">
              By providing the Input Data you authorize Novonesis and its affiliates to use the Input Data for these
              purposes. Novonesis undertakes to keep your Input Data confidential. We do not share your Input Data with
              third parties.
            </Typography>
          </StyledBox>
        </DialogContent>
        <DialogActions style={{ margin: '0', padding: '12px 24px', background: 'white' }}>
          <Button onClick={toggleDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default TermsOfUseDialog;
