import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Amplify } from 'aws-amplify';

import App from './App';
import Maintenance from '@novozymes-digital/laundry-lab/components/Maintenance';
import config from '@novozymes-digital/laundry-lab/config/cognito.json';

import { polyfillObjectFromEntries } from '@novozymes-digital/laundry-lab/utility/polyfills';

import './index.css';
import { store, history } from '@novozymes-digital/laundry-lab/store/index';

const IS_MAINTENANCE_MODE = false;

// Polyfills
polyfillObjectFromEntries();

const authConfig = {
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID || '',
    mandatorySignIn: false,
  },
  oauth: {
    domain: config.cognito.AWS_COGNITO_OAUTH_DOMAIN,
    scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code',
  },
};

Amplify.configure(authConfig);

function render() {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <Provider store={store}>
      <Router history={history}>{IS_MAINTENANCE_MODE ? <Maintenance /> : <App />}</Router>
    </Provider>
  );
}

render();

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
