import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, styled } from '@mui/material';
import Formulations from '@novozymes-digital/laundry-lab/components/Sections/Formulations';
import StainRemoval from '@novozymes-digital/laundry-lab/components/Sections/StainRemoval';
import CostBreakdown from '@novozymes-digital/laundry-lab/components/Sections/CostBreakdown';
import Sustainability from '@novozymes-digital/laundry-lab/components/Sections/Sustainability';
import EcoLabels from '@novozymes-digital/laundry-lab/components/Sections/EcoLabels';
import {
  getCurrentCollectionRegion,
  getIsCreateFormulationPanelOpen,
  getIsEditFormulationPanelOpen,
  getNewFormulationData,
  getSelectedFormulationsData,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { ArrowBack, colors } from '@novozymes-digital/components';
import { Formulation } from '../../store/types';

const TypographyH4 = styled(Typography)({
  font: 'RaleGroteskBase',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '32px',
  textAlign: 'center',
  color: '#000000CC',
});

const GridEmptyBox = styled(Grid)({
  border: '8px dashed ' + colors.dataGrey,
  padding: '10%',
});

const BoxArrowBall = styled(Box)({
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  width: 96,
  height: 96,
  borderRadius: '50%',
  backgroundColor: '#bfe6c4',
  '& > svg': {
    fontSize: 45,
  },
});

const ComparePerformance: React.FunctionComponent = () => {
  const formulations = useSelector(getSelectedFormulationsData);
  const newFormulation = useSelector(getNewFormulationData);
  const isCreateFormulationPanelOpen = useSelector(getIsCreateFormulationPanelOpen);
  const isEditFormulationPanelOpen = useSelector(getIsEditFormulationPanelOpen);
  const region = useSelector(getCurrentCollectionRegion);

  const formulationsToDisplay = useMemo(() => {
    let fs = [...formulations];

    // sort the formulations
    fs.sort((a, b) => {
      // Sort by creation-update date
      if (a.sort_order && b.sort_order && a.sort_order < b.sort_order) {
        return -1;
      } else {
        return 1;
      }
    });

    // creating a new formulation?
    if (newFormulation && newFormulation?.name && isCreateFormulationPanelOpen) {
      fs = [newFormulation, ...formulations];
    }

    // editing a formulation?
    if (newFormulation && newFormulation?.name && isEditFormulationPanelOpen) {
      const formulationIndex = formulations.findIndex(
        (formulation: Formulation) => formulation.id === newFormulation.id
      );
      const formulationsAux = formulations;
      if (formulationIndex > -1) {
        formulationsAux[formulationIndex] = newFormulation;
        formulationsAux.splice(formulationIndex, 1);
      }
      fs = [newFormulation, ...formulationsAux];
    }

    return fs;
  }, [formulations, newFormulation, isCreateFormulationPanelOpen, isEditFormulationPanelOpen]);

  return !formulationsToDisplay.length ? (
    <Grid container direction="column" alignContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center">
        <GridEmptyBox item xs={10}>
          <TypographyH4>You can start working in your collection by:</TypographyH4>
          <br />
          <br />
          <TypographyH4>Creating your first formulation</TypographyH4>
          {['eu', 'cn', 'na'].includes(region) && (
            <>
              <TypographyH4>or</TypographyH4>
              <TypographyH4>Selecting a pre-loaded model formulation</TypographyH4>
            </>
          )}
          <br />
          <br />
          <Grid container item xs={12} justifyContent="center">
            <BoxArrowBall display="flex" alignItems="center" justifyContent="center">
              <ArrowBack fontSize="large" />
            </BoxArrowBall>
          </Grid>
        </GridEmptyBox>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="column">
      <Formulations formulations={formulationsToDisplay} />
      <StainRemoval formulations={formulationsToDisplay} />
      <CostBreakdown formulations={formulationsToDisplay} />
      <Sustainability formulations={formulationsToDisplay} />
      {!['la', 'afr', 'ind', 'me', 'sea'].includes(region) && <EcoLabels formulations={formulationsToDisplay} />}
    </Grid>
  );
};

export default ComparePerformance;
