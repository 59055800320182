import React, { useMemo } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, styled } from '@mui/material';

import { ReactComponent as EcolabelLogo } from '@novozymes-digital/laundry-lab/images/ecolabel_logo.svg';
import { ReactComponent as PassedIcon } from '@novozymes-digital/laundry-lab/images/passed-green-circle-24-px.svg';
import { ReactComponent as FailedIcon } from '@novozymes-digital/laundry-lab/images/failed-red-x-24-px.svg';

import Layout from './Layout';
import { useSelector } from 'react-redux';
import { getEcolabelResultsById, getSelectedBaselineId } from '@novozymes-digital/laundry-lab/store/selectors';
import { EcolabelResult, Formulation } from '@novozymes-digital/laundry-lab/store/types';
import { colors } from '@novozymes-digital/components';

const StyledTableCell = styled(TableCell)(() => ({
  lineHeight: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const ecoLabels: { label: keyof EcolabelResult; title: string; description: string }[] = [
  {
    label: 'criterion 1',
    title: 'Dosage requirements',
    description: 'For heavy-duty detergent, colour-safe detergent the dosage shall not exceed 16 g/kg of laundry.',
  },
  {
    label: 'criterion 2',
    title: 'Toxicity to aquatic organisms',
    description:
      'For heavy-duty detergent, colour-safe detergent the CDV shall not exceed 31.500 L of water/kg of laundry.',
  },
  {
    label: 'criterion 3',
    title: 'Biodegradability',
    description: 'All surfactants displayed in the tool are anaerobically biodegradable with the exception of LAS.',
  },
];

const sortFormulations = (formulations: Formulation[], baseLineId: string) => {
  if (!baseLineId) return formulations;

  return [...formulations].sort((a, b) => {
    return a.id === baseLineId ? 1 : b.id === baseLineId ? -1 : 0;
  });
};

interface Props {
  formulations: Formulation[];
}

const EcoLabels: React.FunctionComponent<Props> = ({ formulations }: Props) => {
  const ecolabelResultGetter = useSelector(getEcolabelResultsById);
  const baselineId = useSelector(getSelectedBaselineId) || '';
  const sortedFormulations = useMemo(() => sortFormulations(formulations, baselineId), [formulations, baselineId]);

  return (
    <Layout data-cy="sustainabilitylabel-data-panel" title="EU Ecolabel" id="sustainabilitylabel">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <EcolabelLogo width={64} />
              </TableCell>
              {sortedFormulations.map((formulation) => (
                <StyledTableCell align="center" key={`${formulation.id}-ecolabel-label`}>
                  {formulation.name + (!!baselineId && formulation.id === baselineId ? ' (baseline)' : '')}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ecoLabels.map(({ label, title, description }, index) => (
              <TableRow key={index}>
                <TableCell
                  width="250"
                  style={{
                    borderTop: index === ecoLabels.length - 1 ? '1px solid ' + colors.spaceGreyB : '',
                  }}
                >
                  <Typography variant="body2">
                    <b>{label.charAt(0).toUpperCase() + label.slice(1)}</b>
                  </Typography>
                  <Typography variant="body2">
                    <b>{title}</b>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {description}
                  </Typography>
                </TableCell>
                {sortedFormulations.map((formulation) => {
                  const data = ecolabelResultGetter(formulation.id);
                  return (
                    data && (
                      <TableCell
                        key={`${formulation.id}-ecolabel-data`}
                        style={{
                          textAlign: 'center',
                          borderTop: index === ecoLabels.length - 1 ? '1px solid ' + colors.spaceGreyB : '',
                        }}
                      >
                        {data[label] === 'approved' ? (
                          <PassedIcon data-cy="passed-icon" width={27} height={27} />
                        ) : (
                          <FailedIcon data-cy="failed-icon" width={27} height={27} />
                        )}
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={formulations.length + 1} style={{ border: 'none' }}>
                <Typography variant="body2" color="textSecondary">
                  Note: <br />A full description of the EU Ecolabel criteria for laundry detergents can be found{' '}
                  <a
                    href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2017.180.01.0063.01.ENG&toc=OJ:L:2017:180:TOC#PP4Contents"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here.
                  </a>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default EcoLabels;
