import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, InputAdornment, styled } from '@mui/material';
import { colors } from '@novozymes-digital/components';
import LockIcon from '@novozymes-digital/laundry-lab/icons/LockIcon';
import {
  getCurrentCollectionPrices,
  getCurrentCollectionCurrency,
  getUserUnits,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { useSelector } from 'react-redux';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
import Slider from '@material-ui/core/Slider';

const StyledLabel = styled('label')({
  fontSize: '1rem',
  fontWeight: 'bold',
});

const StyledTextField = styled(TextField)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiInput-input': {
    color: colors.black67,
    fontWeight: 'bold',
    textAlign: 'right',
    width: '45px',
  },
  '& .MuiInput-root:before': {
    borderBottom: 'none !important',
  },
  '& .MuiInput-root:after': {
    borderBottom: 'none',
  },
});

const StyledTypography = styled(Typography)({
  marginLeft: 'auto',
  fontSize: '14px',
});

const StyledSpan = styled('span')({
  fontSize: '12px',
  color: '#b5b5b5',
});

const StyledMinMaxFontText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '20px',
  color: colors.black47,
  marginBottom: '-5px',
});

const StyledSlider = styled(Slider)`
  && {
    .MuiSlider-rail {
      background-color: ${(props) => props.theme.palette.grey[400]};
    }
    .MuiSlider-mark {
      height: 10;
      top: calc(50% - 5px);
      background-color: ${(props) => props.theme.palette.grey[600]};
    }
    .MuiSlider-markActive {
      background-color: ${(props) => props.theme.palette.grey[600]};
    }
    .MuiSlider-markLabel {
      font-size: 12px;
    }
  }
`;

interface Props {
  ingredient: any;
  ingredientsTotal: number;
  setCurrentValues: any;
  fillersName: string[];
  value: number;
  min: number;
  max: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  interval?: number;
  displayInterval?: boolean;
  label?: string;
  labelX?: string;
  legend?: string;
  step?: number;
}

const FillerSingle: React.FunctionComponent<Props> = ({
  ingredient,
  ingredientsTotal,
  setCurrentValues,
  fillersName,
  value,
  min = 0,
  max = 10,
  onChange,
  interval = 0.5,
  label,
  labelX,
  legend,
  step = 5,
  displayInterval = true,
  ...props
}: Props) => {
  const ingredientCosts: any = useSelector(getCurrentCollectionPrices);
  const currency = useSelector(getCurrentCollectionCurrency);
  const userUnits = useSelector(getUserUnits);

  const doseMarks = Array((max + interval - min) / interval)
    .fill('')
    .map((_, i) => min + i * interval)
    .map((v) => ({
      value: v,
      label: v,
    }));
  const [sliderValue, setSliderValue] = useState(value);
  const [fillerInputValue, setFillerInputValue] = useState(value);
  const [overLimit, setOverLimit] = useState(false);

  const handleValueUpdating = useCallback(
    (value: string | number) => {
      let newValue = trunc(Number(value), 1);
      if (newValue > max) {
        newValue = max;
      } else if (newValue < min) {
        newValue = min;
      }

      if (onChange) {
        onChange(newValue);
      }
      setSliderValue(trunc(newValue, 1));
      setFillerInputValue(trunc(newValue, 1));
    },
    [max, min, onChange]
  );

  const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    handleValueUpdating(trunc(Number(event.target.value), 1));
  };

  const handleTempChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    setFillerInputValue(trunc(Number(event.target.value), 1));
  };

  const handleSliderChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    if (props.disabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    setSliderValue(trunc(newValue, 1));
  };

  const handleSliderCommitChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    if (props.disabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    handleValueUpdating(trunc(newValue, 1));
  };

  useEffect(() => {
    handleValueUpdating(value);
  }, [handleValueUpdating, value]);

  // Update fillers when ingredients change
  useEffect(() => {
    if (ingredientsTotal <= 0) {
      setFillerInputValue(0);

      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: 0,
      }));
      setOverLimit(true);
    } else if (ingredientsTotal > 0) {
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: trunc(ingredientsTotal, 1),
      }));

      setOverLimit(false);
    }

    // when the ingredientsTotal go from >= 100% to < 100%
    if (ingredientsTotal > 0 && overLimit === true) {
      setOverLimit(false);
      setCurrentValues((newValues: any) => ({
        ...newValues,
        [fillersName[0]]: trunc(ingredientsTotal, 1),
      }));
      setFillerInputValue(trunc(ingredientsTotal, 1));
    }
  }, [ingredientsTotal]);

  return (
    <Box p={0}>
      <Grid container item alignItems="center">
        <StyledLabel>{label}</StyledLabel>
        <StyledTextField
          value={String(trunc(fillerInputValue, 1))}
          id="filler-number"
          name="fillerNumber"
          variant="standard"
          disabled={props.disabled}
          onBlur={handleChange}
          onChange={handleTempChange}
          InputProps={{
            inputProps: { min: min, max: 1000 },
            endAdornment: (
              <InputAdornment position="end">
                <span style={{ fontWeight: 'bold' }}>{legend}</span>
              </InputAdornment>
            ),
          }}
        />
        <LockIcon />
        {ingredient ? (
          <StyledTypography align="right">
            {`${ingredientCosts[ingredient]}`}
            <StyledSpan>{` ${currency}/${userUnits.weight}`}</StyledSpan>
          </StyledTypography>
        ) : (
          ''
        )}
      </Grid>
      {!displayInterval && (
        <Grid container item xs={12} alignItems="baseline" justifyContent="space-between">
          <StyledMinMaxFontText>{min}</StyledMinMaxFontText>
          <StyledMinMaxFontText>{max}</StyledMinMaxFontText>
        </Grid>
      )}
      <StyledSlider
        value={trunc(sliderValue, 1)}
        marks={displayInterval ? doseMarks : undefined}
        step={step}
        min={min}
        max={max}
        valueLabelDisplay="off"
        onChange={onChange ? handleSliderChange : undefined}
        onChangeCommitted={handleSliderCommitChange}
        {...props}
      />
      <Grid container justifyContent="center">
        <Typography align="center" style={{ fontSize: '14px' }}>
          {labelX}
        </Typography>
      </Grid>
    </Box>
  );
};

export default FillerSingle;
