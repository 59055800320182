import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Plus } from '@novozymes-digital/components';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormulationSelection from './FormulationSelection';
import FormulationPanel from './FormulationPanel';
import {
  closeNewFormulation,
  openNewFormulation,
  closeEditFormulation,
  createFormulation,
  updateFormulation,
  toggleSelectFormulation,
} from '@novozymes-digital/laundry-lab/store/actions/actions';
import {
  getBlendEnzymes,
  getBlendLimits,
  getCurrentCollectionRegion,
  getEditFormulationData,
  getEditFormulationIsSelected,
  getIngredientMaxValue,
  getIsCreateFormulationPanelOpen,
  getIsEditFormulationPanelOpen,
  getRegions,
  getUserAllGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { Formulation } from '@novozymes-digital/laundry-lab/store/types';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
import { styled } from '@mui/material';

const StyledBox = styled(Box)(() => ({
  bottom: '0',
  paddingBottom: '50px',
  paddingTop: '30px',
  top: '65px',
  position: 'sticky',
  overflowX: 'hidden',
  background: '#EEFFF0',
  maxHeight: 'calc(100vh - 65px)',
}));

const StyledPlus = styled(Plus)(() => ({
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  borderRadius: '50%',
  backgroundColor: '#C5DA00',
}));

const StyledGrid = styled(Grid)(() => ({
  paddingLeft: '20px',
  paddingRight: '20px',
  display: 'grid',
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '14px',
}));

const LeftPanel: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const showCreatePanel = useSelector(getIsCreateFormulationPanelOpen);
  const showEditPanel = useSelector(getIsEditFormulationPanelOpen);
  const editFormulationData = useSelector(getEditFormulationData);
  const editFormulationIsSelected = useSelector(getEditFormulationIsSelected);
  const userAllGrants = useSelector(getUserAllGrants);

  const handleCreateFormulationClick = () => {
    dispatch(openNewFormulation());
  };

  const handleOnNewFormulationPanelClose = () => {
    dispatch(closeNewFormulation());
  };

  const handleOnNewFormulationPanelSave = (formulationData: Formulation) => {
    dispatch(createFormulation({ formulationData }));
  };

  const handleOnEditFormulationPanelClose = (formulation: Formulation) => {
    dispatch(closeEditFormulation());
    dispatch(toggleSelectFormulation({ formulationId: formulation.id, isSelected: !editFormulationIsSelected }));
  };

  const handleOnEditFormulationPanelSave = (formulation: Formulation) => {
    dispatch(updateFormulation({ formulation }));
    dispatch(toggleSelectFormulation({ formulationId: formulation.id, isSelected: !editFormulationIsSelected }));
  };

  const region = useSelector(getCurrentCollectionRegion);

  const dose = useSelector(getRegions)[region].dose_max;
  const temperature = useSelector(getRegions)[region].uom_temperature_options;
  const ingredientMaxValues = useSelector(getIngredientMaxValue);
  const blendLimits = useSelector(getBlendLimits);
  const blends = useSelector(getBlendEnzymes);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

  const limits: any = {
    na: {
      las: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['las'] ?? 0) * water_volume), 1),
      aes: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['aes'] ?? 0) * water_volume), 1),
      aeo: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['aeo'] ?? 0) * water_volume), 1),
      liquanase35l: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['liquanase35l'] ?? 0) * water_volume), 2),
      amplifyprime100l: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['amplifyprime100l'] ?? 0) * water_volume), 2),
      mannaway200l: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['mannaway200l'] ?? 0) * water_volume), 2),
      xpect1000l: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['xpect1000l'] ?? 0) * water_volume), 2),
      soap: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['soap'] ?? 0) * water_volume), 1),
      citrate: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['na']['citrate'] ?? 0) * water_volume), 1),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
      medleyEssential200l: (dosage: number): number =>
        trunc(
          Object.entries(blendLimits['na']['medleyEssential200l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0,
          2
        ),
      medleyFlex300l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['na']['medleyFlex300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleySelect300l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['na']['medleySelect300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyPure300l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['na']['medleyPure300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyBrilliant300l: (dosage: number): number =>
        trunc(
          Object.entries(blendLimits['na']['medleyBrilliant300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0,
          2
        ),
    },
    la: {
      lipex100t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['la']['lipex100t'] ?? 0), 2),
      mannaway4t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['la']['mannaway4t'] ?? 0), 2),
      savinase12t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['la']['savinase12t'] ?? 0), 2),
      stainzymeplus12t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['la']['stainzymeplus12t'] ?? 0), 2),
      celluclean4500t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['la']['celluclean4500t'] ?? 0), 2),
      las: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['la']['las'] ?? 0), 1),
      sodiumcarbonate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['la']['sodiumcarbonate'] ?? 0), 1),
      sodiumsulfate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['la']['sodiumsulfate'] ?? 0), 1),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
      medleyOptiwash1750s: (dosage: number): number =>
        trunc(
          Object.entries(blendLimits['la']['medleyOptiwash1750s']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0,
          2
        ),
      medleyOptiwash3500s: (dosage: number): number =>
        trunc(
          Object.entries(blendLimits['la']['medleyOptiwash3500s']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0,
          2
        ),
      medleyOptiwash400splus: (dosage: number): number =>
        trunc(
          Object.entries(blendLimits['la']['medleyOptiwash400splus']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0,
          2
        ),
      medleyAdvance200t: (dosage: number): number =>
        trunc(Object.entries(blendLimits['la']['medleyAdvance200t']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
    },
    eu: {
      // las: (dosage: number, water_volume: number): number =>
      //   trunc((ingredientMaxValues['eu']['las'] ?? 0) * dosage ** -0.645 * 100, 1),
      las: (dosage: number): number => trunc(2.4333 * dosage ** -0.645 * 100, 1),
      // aes: (dosage: number): number =>
      //   trunc((ingredientMaxValues['eu']['aes'] ?? 0) * dosage ** -0.645 * 100, 1),
      aes: (dosage: number): number => trunc(2.6907 * dosage ** -0.645 * 100, 1),
      // aeo: (dosage: number, water_volume: number): number =>
      //   trunc((ingredientMaxValues['eu']['aeo'] ?? 0) * dosage ** -0.645 * 100, 1),
      aeo: (dosage: number): number => trunc(2.6907 * dosage ** -0.645 * 100, 1),
      lipexevity200l: (dosage: number, water_volume: number): number =>
        trunc(((ingredientMaxValues['eu']['lipexevity200l'] ?? 0) / (dosage / water_volume)) * 100, 2),
      progressuno100l: (dosage: number, water_volume: number): number =>
        trunc(((ingredientMaxValues['eu']['progressuno100l'] ?? 0) / (dosage / water_volume)) * 100, 2),
      amplifyprime100l: (dosage: number, water_volume: number): number =>
        trunc(((ingredientMaxValues['eu']['amplifyprime100l'] ?? 0) / (dosage / water_volume)) * 100, 2),
      mannaway200l: (dosage: number, water_volume: number): number =>
        trunc(((ingredientMaxValues['eu']['mannaway200l'] ?? 0) / (dosage / water_volume)) * 100, 2),
      soap: (dosage: number, water_volume: number): number =>
        trunc(((ingredientMaxValues['eu']['soap'] ?? 0) / (dosage / water_volume)) * 100, 2),
      // citrate: (dosage: number): number =>
      //   trunc((ingredientMaxValues['eu']['citrate'] ?? 0) * dosage ** -0.692 * 100, 1),
      citrate: (dosage: number): number => trunc(0.8126 * dosage ** -0.692 * 100, 1),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
      medleyCore200l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['eu']['medleyCore200l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyBoost300l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['eu']['medleyBoost300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyFlex300l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['eu']['medleyFlex300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyBright200l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['eu']['medleyBright200l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyAdvance300l: (dosage: number): number =>
        trunc(Object.entries(blendLimits['eu']['medleyAdvance300l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2),
      medleyBrilliant400l: (dosage: number): number => {
        const amylaseMaxValue = blends.eu.medleyBrilliant400l.find(
          (enzyme: { name: string }) => enzyme.name === 'amplifyprime100l'
        )?.enzyme_max_value;
        const defaultAmplifyprime100l = ingredientMaxValues['eu']['amplifyprime100l'] ?? 0;
        const scaleFactor = defaultAmplifyprime100l !== 0 ? amylaseMaxValue / defaultAmplifyprime100l : 1;

        return grantGlobalCosmed
          ? trunc(
              scaleFactor *
                (Object.entries(blendLimits['eu']['medleyBrilliant400l']).find((x) => Number(x[0]) == dosage)?.[1] ??
                  0),
              2
            )
          : 0;
      },
    },
    cn: {
      // las: (): number => ingredientMaxValues['cn']['las'] ?? 0,
      las: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['las'] ?? 0) * water_volume), 1),
      aes: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['aes'] ?? 0) * water_volume), 1),
      aeo: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['aeo'] ?? 0) * water_volume), 1),
      progressuno100l: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['progressuno100l'] ?? 0) * water_volume), 2),
      amplifyprime100l: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['amplifyprime100l'] ?? 0) * water_volume), 2),
      soap: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['soap'] ?? 0) * water_volume), 1),
      citrate: (dosage: number, water_volume: number): number =>
        trunc((100 / dosage) * ((ingredientMaxValues['cn']['citrate'] ?? 0) * water_volume), 1),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
    },
    afr: {
      // surfactants
      las: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['afr']['las'] ?? 0), 1),
      // builders
      sodiumcarbonate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['sodiumcarbonate'] ?? 0), 1),
      silicate: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['afr']['silicate'] ?? 0), 1),
      // fillers
      sodiumsulfate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['sodiumsulfate'] ?? 0), 1),
      sodiumchloride: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['sodiumchloride'] ?? 0), 1),
      // enzymes
      savinase16t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['savinase16t'] ?? 0), 2),
      stainzymeplus12t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['stainzymeplus12t'] ?? 0), 2),
      lipex100t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['afr']['lipex100t'] ?? 0), 2),
      celluclean4500t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['celluclean4500t'] ?? 0), 2),
      mannaway4t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['mannaway4t'] ?? 0), 2),
      xpect1000t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['afr']['xpect1000t'] ?? 0), 2),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
    },
    ind: {
      // surfactants
      las: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['ind']['las'] ?? 0), 1),
      // builders
      sodiumcarbonate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['sodiumcarbonate'] ?? 0), 1),
      silicate: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['ind']['silicate'] ?? 0), 1),
      // fillers
      dolomite: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['ind']['dolomite'] ?? 0), 1),
      sodiumchloride: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['sodiumchloride'] ?? 0), 1),
      // enzymes
      savinase16t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['savinase16t'] ?? 0), 2),
      stainzymeplus12t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['stainzymeplus12t'] ?? 0), 2),
      lipex100t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['ind']['lipex100t'] ?? 0), 2),
      celluclean4500t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['celluclean4500t'] ?? 0), 2),
      mannaway4t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['mannaway4t'] ?? 0), 2),
      xpect1000t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['ind']['xpect1000t'] ?? 0), 2),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
    },
    me: {
      // surfactants
      las: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['me']['las'] ?? 0), 1),
      // builders
      sodiumcarbonate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['me']['sodiumcarbonate'] ?? 0), 1),
      silicate: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['me']['silicate'] ?? 0), 1),
      // fillers
      // enzymes
      savinase16t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['me']['savinase16t'] ?? 0), 2),
      stainzymeplus12t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['me']['stainzymeplus12t'] ?? 0), 2),
      lipex100t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['me']['lipex100t'] ?? 0), 2),
      celluclean4500t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['me']['celluclean4500t'] ?? 0), 2),
      mannaway4t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['me']['mannaway4t'] ?? 0), 2),
      xpect1000t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['me']['xpect1000t'] ?? 0), 2),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
    },
    sea: {
      // surfactants
      las: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['sea']['las'] ?? 0), 1),
      // builders
      sodiumcarbonate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['sodiumcarbonate'] ?? 0), 1),
      silicate: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['sea']['silicate'] ?? 0), 1),
      // fillers
      sodiumsulfate: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['sodiumsulfate'] ?? 0), 1),
      // enzymes
      savinase16t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['savinase16t'] ?? 0), 2),
      stainzymeplus12t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['stainzymeplus12t'] ?? 0), 2),
      lipex100t: (dosage: number): number => trunc((100 / dosage) * (ingredientMaxValues['sea']['lipex100t'] ?? 0), 2),
      celluclean4500t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['celluclean4500t'] ?? 0), 2),
      mannaway4t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['mannaway4t'] ?? 0), 2),
      xpect1000t: (dosage: number): number =>
        trunc((100 / dosage) * (ingredientMaxValues['sea']['xpect1000t'] ?? 0), 2),
      temperature: (): number[] => temperature,
      dose: (): number => dose,
    },
  };

  return (
    <StyledBox>
      <Grid container item xs={12}>
        {showCreatePanel && (
          <FormulationPanel
            title="Create new formulation"
            style={{
              position: 'inherit',
              borderRadius: 24,
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            }}
            show={showCreatePanel}
            onClose={handleOnNewFormulationPanelClose}
            onSave={handleOnNewFormulationPanelSave}
            limits={limits}
          />
        )}
      </Grid>
      <Grid container item xs={12}>
        {showEditPanel && (
          <FormulationPanel
            title="Editing"
            style={{ position: 'inherit', borderRadius: 24 }}
            show={showEditPanel}
            onClose={handleOnEditFormulationPanelClose}
            onSave={handleOnEditFormulationPanelSave}
            formulationData={editFormulationData}
            limits={limits}
          />
        )}
      </Grid>
      {!showCreatePanel && !showEditPanel && (
        <Grid container>
          <Grid container item xs={12} justifyContent="center">
            <StyledGrid container item xs={12} justifyContent="flex-start">
              <Typography variant="subtitle1" style={{ paddingTop: 30 }}>
                Create new formulation
              </Typography>
              <Grid container item xs={12} justifyContent="flex-start">
                <Button
                  data-cy="btn-newformulation-dialog"
                  id="btn-newformulation-dialog"
                  onClick={handleCreateFormulationClick}
                  style={{ paddingLeft: 0 }}
                >
                  <StyledPlus />
                  <StyledTypography data-cy="btn-newformulation-text" style={{ marginLeft: '5px' }}>
                    Create new formulation
                  </StyledTypography>
                </Button>
              </Grid>
              <Divider variant="middle" />
            </StyledGrid>
            <FormulationSelection />
          </Grid>
        </Grid>
      )}
    </StyledBox>
  );
};

export default LeftPanel;
